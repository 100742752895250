import React from 'react';
import Footer from '../components/Footer'
import Banner from '../components/Banner';

import '../styles/Quote.css';

function Quote() {

    const title = 'Demander un devis';
    const titleText = 'Vous avez un projet? Dites nous tout on vous réponds';

    return (
      <div>
        <Banner title={title} titleText={titleText} />

        <div className="Quote">
          <h2 className="textQuote">
            Page en cour de réalisation. Bientôt disponible !
          </h2>
          <Footer />
        </div>
      </div>
    );
}

export default Quote