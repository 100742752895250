import { apiUrl } from '../datas/apiUrl';
import axios from 'axios';
import { useState, useEffect } from 'react';

import React from 'react';

function CounterVisits() {
  const [visitsToSend, setVisitsToSend] = useState(0);

  useEffect(() => {
    axios
      .get(`${apiUrl}/counter_visits/1`)
      .then((response) => response.data)
      .then((data) => {
        // console.log(data);
        setVisitsToSend(data);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }, []);

  return (
    <div className="nbVisits">
      <h2>Nombre de visiteurs:</h2>
      <ul className="nbVisitsList">
        <li className="nbVisitsLi">
          <p>
            Page d'accueil: <br />
            {visitsToSend.homeVisits} visites
          </p>
        </li>
        <li className="nbVisitsLi">
          <p>
            Vidéothèque: <br />
            {visitsToSend.videoLibraryVisits} visites
          </p>
        </li>
        <li className="nbVisitsLi">
          <p>
            Page à propos: <br />
            {visitsToSend.aboutVisits} visites
          </p>
        </li>
        <li className="nbVisitsLi">
          <p>
            Page de contact: <br />
            {visitsToSend.contactVisits} visites
          </p>
        </li>
        <li className="nbVisitsLi">
          <p>
            Page education à l'image: <br />
            {visitsToSend.imageEducationVisits} visites
          </p>
        </li>
        <li className="nbVisitsLi">
          <p>
            Nombre total de visite: <br />
            {visitsToSend.totalVisits} visites
          </p>
        </li>
      </ul>
    </div>
  );
}

export default CounterVisits;
