import React from 'react';
import '../styles/NotFound.css'
import { Link } from 'react-router-dom'

function NotFound() {
    return (
      <div className="bgi-notfound">
        <h1>
          404: Allez ch'é po l'bonne page !! Arcominche par <a href="/">ichi</a>
        </h1>
        <h2 className="accueil-menu-title">
          <Link to="/" className="accueil-menu-title-href">
            Retourner à l'accueil
          </Link>
        </h2>
      </div>
    );
}

export default NotFound;
