import React from 'react';
import { useEffect } from 'react';
import '../styles/Home.css';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import teaser from '../assets/videos/TeaserMdr.mp4';
import { apiUrl } from '../datas/apiUrl';
import axios from 'axios';

// import logoGif from '../assets/logos/logo-mines.gif'

function Home() {
  // A utiliser pour avoir le nombre de visites apparaitre dans le localStorage =>

  // const [visits, setVisits] = useState(0);
  // const [storeHomeVisits, setStoreHomeVisits] = useState({});
  // const [visitsToSend, setVisitsToSend] = useState(0);
  // const [totalVisits, setTotalVisits] = useState(0);
  // const [homeVisits, setHomeVisits] = useState(0);
  // const [visitsHomeToSend, setVisitsHomeToSend] = useState(0);

  useEffect(() => {
    let home;
    let total;
    axios
      .get(`${apiUrl}/counter_visits/1`)
      .then((response) => response.data)
      .then((data) => {
        // console.log(
        //   'le data pour le visitToSend avant tout changement: ',
        //   data
        // );
        // setVisitsToSend(data);
        home = data.homeVisits;
        total = data.totalVisits;
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        };
        const bodyParameters = {
          totalVisits: total + 1,
          homeVisits: home + 1,
        };
        axios
          .put(`${apiUrl}/counter_visits/1`, bodyParameters, config)
          .then(function (response) {
            //handle success
            // console.log(response);
            // setVisitsToSend({
            //   homeVisits: bodyParameters.homeVisits,
            //   totalVisits: bodyParameters.totalVisits
            // });
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, []);

  // Pour avoir le nombre de visites apparaitre dans le localStorage =>

  // useEffect(() => {
  //   if(window.localStorage.getItem('Bearer')) {
  //     // Dans le cas où il y a un token
  //     console.log("Connecté");
  //     // setIsLoggedIn(true);
  //   }
  //   else {
  //     // Dans le cas où on n'a pas de token
  //     console.log("Non connecté");
  //     // setIsLoggedIn(false);
  //   }

  //   // Détection
  //   let nbVisits;
  //   if(typeof localStorage!='undefined') {
  //     axios
  //       .put(`${apiUrl}/counter_visits/1`)
  //       .then((response) => response.data)
  //       .then((data) => {
  //         console.log('le data pour le visitToSend avant tout changement: ', data);
  //         setVisitsToSend(data);
  //       })
  //       .catch((error) => {
  //         console.log(error.response);
  //       });
  //     // Récupération de la valeur dans web storage
  //     console.log('le localStorage: ', localStorage.getItem('homeVisits'));
  //     nbVisits = localStorage.getItem('homeVisits');
  //     console.log('le nbVisits après le passage du localStorage', nbVisits);
  //     // Vérification de la présence du compteur
  //     if(nbVisits!==null) {
  //       // Si oui, on convertit en nombre entier la chaîne de texte qui fut stockée
  //       nbVisits=parseInt(nbVisits);
  //       console.log('nbvisits pas égal à null: ', nbVisits);
  //     } else {
  //       nbVisits=0;
  //       console.log('nbVisits égal à null donc égal maintenant à: ', nbVisits);
  //     }
  //     // Incrémentation
  //     nbVisits++;
  //     console.log('incrémentation nbVisits: ', nbVisits);
  //     // Stockage à nouveau en attendant la prochaine visite...
  //     localStorage.setItem('homeVisits', nbVisits);
  //     let totalVisits;
  //     if(localStorage.getItem('allVisits') !== null) {
  //       totalVisits = parseInt(localStorage.getItem('allVisits'));
  //     } else {
  //       totalVisits=0;
  //     }
  //     totalVisits++;
  //     localStorage.setItem('allVisits', totalVisits);
  //     console.log('total: ', localStorage.getItem('allVisits'));
  //     console.log('le localstorage: ', localStorage.getItem('homeVisits'));
  //     // Affichage dans la page
  //     setVisits(nbVisits);
  //     // enregistrer dans un fichier, une session ou dans la bdd
  //     setStoreHomeVisits({
  //       visits: nbVisits
  //     });
  //   } else {
  //     alert("localStorage n'est pas supporté !");
  //   }
  // }, [])

  // console.log('le nb de visites: ', visits, '&', storeHomeVisits);
  // let visited = JSON.stringify(storeHomeVisits);
  // sessionStorage.setItem("HomeVisits", visited);
  // console.log(visited);

  // console.log('le visitsToSend.homeVisits: ', visitsToSend.homeVisits + nbVisits);
  // setVisitsToSend({homeVisits: visitsToSend.homeVisits + nbVisits});
  // console.log('visitsToSend après incrémentation dans la homeVisits: ', visitsToSend);
  // setVisitsToSend(visitsToSend.homeVisits + nbVisits);
  // console.log('visitsToSend après incrémentation dans nb visits: ', visitsToSend);

  // visited = sessionStorage.getItem("allVisits");
  // setStoreHomeVisits(JSON.parse(visited));
  // console.log('le storeHomeVisits après le parse: ', storeHomeVisits);

  // Test pour la récupération de l'adresse Ip publique de l'utilisateur
  // function monAdresseIP()
  // {
  // var ip = false;
  // if (window.XMLHttpRequest) xmlhttp = new XMLHttpRequest();
  // else xmlhttp = new ActiveXObject(Microsoft.XMLHTTP);
  // xmlhttp.open(GET,'https://db-ip.com');
  // xmlhttp.send();
  // var reponse = JSON.Parse(xmlhttp.responseText);
  // //On suppose que l'adresse IP est stockée avec la clé ip. Regardez les exemples fournis par les services pour savoir quelle clé correspond à l'adresse IP
  // if (reponse[ip])
  // ip = reponse[ip]
  // return ip;
  // }
  // console.log('le XML... : ', window.XMLHttpRequest);
  // console.log("l'adresse ip? : ", request.connection.remoteAddress);

  return (
    <div className="home-frame">
      {/* <p style={{...{width: '100%'}, ...{color: 'yellow'}, ...{position: 'absolute'}, ...{textAlign: 'right'}, ...{paddingRight: '15px'}, ...{display: 'inline-block'}}}>Toutes les pages du sites ont été vu au total <span>{visitsToSend.totalVisits}</span> fois. Et cette page a été vu <span>{visitsToSend.homeVisits}</span> fois</p> */}
      <div className="home-bgi">
        {/* <div className='home-logo-frame'>
          <img src={logoGif} alt="logo mines de rien animé" id='logo-gif'/>
        </div> */}
      </div>
      <div className="home-text-frame">
        <video id="background-video" autoPlay loop muted>
          <source src={teaser} type="video/mp4" />
        </video>
        <div className="home">
          <script
            type="text/javascript"
            src="//compteur.websiteout.com/js/7/0/0/1"
          ></script>
          <h1 className="accueil-menu-main-title">
            Cinéma et création en pays minier
          </h1>
          <div className="accueil-menu">
            <h2 className="menu-title">
              <Link to="video-library" className="accueil-menu-title-href">
                Voir nos réalisations
              </Link>
            </h2>
            <h2 className="menu-title">
              <Link to="contact" className="accueil-menu-title-href">
                Nous contacter
              </Link>
            </h2>
            <h2 className="menu-title">
              <Link to="image-education" className="accueil-menu-title-href">
                Éduquer à l’image
              </Link>
            </h2>
            <h2 className="menu-title">
              <Link to="about" className="accueil-menu-title-href">
                Découvrir notre univers
              </Link>
            </h2>
            {/* <h2 className="menu-title">
              <Link to="contact" className="accueil-menu-title-href">
                Demander un devis
              </Link>
            </h2> */}
            {/* <h2 className="menu-title">
              <Link to="login" className="accueil-menu-title-href">
                {isLoggedIn ? "Se déconnecter" : "Se connecter"}
              </Link>
            </h2> */}
          </div>
        </div>
      </div>
      <div className="home-text-presentation">
        <p className="home-text-p">
          <strong>Mines de Rien</strong> est un collectif de création et de
          production artistique et cinéma. Notre activité s’articule autour de
          trois axes :
        </p>
        <p className="home-text-p">
          <strong>prestations audiovisuelles</strong> au service des acteurs
          culturels, <strong>ateliers pédagogiques</strong> d’éducation à
          l’image et à la fabrication d’un film et, finalement,{' '}
          <strong>création artistique pluridisciplinaire</strong>.
        </p>
        <p className="home-text-p">
          Enfants des terrils et des briques rouges, notre travail est fortement
          imprégné de l’histoire et des paysages du Bassin minier du Nord et du
          Pas-de-Calais, où nous avons grandi. C’est à sa population que nous
          souhaitons transmettre notre passion du cinéma.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
