import React from 'react';
import Banner from '../components/Banner';
import Footer from '../components/Footer';
import { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/AddVideo.css';
import { apiUrl } from '../datas/apiUrl';

function AddCasting() {
  const titlePage = 'Ajout De Personnes qui ont travaillé pour Mines De Rien';
  const titleTextPage = 'Entrez les infos des gens';

  const [alias, setAlias] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [phone, setPhone] = useState('');
  const [presentation, setPresentation] = useState('');

  const [videos, setVideos] = useState([]);
  const [videoId, setVideoId] = useState('');

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (window.localStorage.getItem('Bearer')) {
      // Dans le cas où il y a un token
      const url = `${apiUrl}/videos`;
      axios
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          // console.log(data);
          setVideos(data);
        });
      console.log('Connecté');
      setIsLoggedIn(true);
    } else {
      // Dans le cas où on n'a pas de token
      console.log('Non connecté');
      setIsLoggedIn(false);
    }
  }, []);

  const optionVideos = videos.map(({ id, groupName, title }) => (
    <option value={id}>
      {id}: {groupName} - {title}
    </option>
  ));

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const bodyParameters = {
      alias: alias,
      firstname: firstname,
      lastname: lastname,
      presentation: presentation,
      jobTitle: jobTitle,
      phone: phone,
      videos: [`/api/videos/${videoId}`],
    };
    const config = {
      headers: {
        Authorization: 'Bearer ' + window.localStorage.getItem('Bearer'),
      },
    };
    axios
      .post(`${apiUrl}/workers`, bodyParameters, config)
      .then(function (response) {
        //handle success
        // console.log(response);
        setAlias('');
        setFirstname('');
        setLastname('');
        setPresentation('');
        setJobTitle('');
        setPhone('');
        alert('Les données ont bien été enregistré');
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };

  if (isLoggedIn) {
    return (
      <div>
        <Banner title={titlePage} titleText={titleTextPage} />
        <div className="addVideo">
          <h2 className="textAddVideo">Formulaire vidéo</h2>
          <form onSubmit={handleFormSubmit} className="form">
            <select
              class="form-select"
              aria-label="Default select example"
              onChange={(e) => setVideoId(e.target.value)}
            >
              <option value="">
                Sélectionnez une Vidéo pour laquelle la personne a travaillé
              </option>
              {optionVideos}
            </select>

            <label>Alias</label>
            <input
              type="text"
              name="alias"
              value={alias}
              onChange={(e) => setAlias(e.target.value)}
            />

            <label>Prénom</label>
            <input
              type="text"
              name="firstname"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
            />

            <label>Nom</label>
            <input
              type="text"
              name="lastname"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
            />

            <label>Presentation</label>
            <textarea
              type="text"
              name="presentation"
              value={presentation}
              onChange={(e) => setPresentation(e.target.value)}
            ></textarea>

            <label>Poste</label>
            <input
              type="text"
              name="jobTitle"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
            />

            <label>Téléphone</label>
            <input
              type="text"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />

            <input type="submit" value="Ajouter la personne" />
          </form>
        </div>
        <Footer />
      </div>
    );
  } else {
    return (
      <div>
        <Banner title={titlePage} titleText={titleTextPage} />
        <div className="addVideo">
          <h1 className="textAddVideo">
            Vous n'avez pas accés à l'espace administration. <br />
            Veuillez vous connecter.
          </h1>
        </div>
        <Footer />
      </div>
    );
  }
}

export default AddCasting;
