import React from 'react';
import { Routes, Route } from 'react-router-dom';
import '../styles/App.css';
import NavBar from './NavBar';
import Home from '../pages/Home';
import VideoLibrary from '../pages/VideoLibrary';
import VideoScreen from '../pages/VideoScreen';
import Contact from '../pages/Contact';
import ImageEducation from '../pages/ImageEducation';
import About from '../pages/About';
import Quote from '../pages/Quote';
import Register from '../pages/Register';
import Admin from '../pages/Admin';
import AddVideos from '../pages/AddVideos';
import Login from '../pages/Login';
import AddImageVideo from '../pages/AddImageVideo';
import NotFound from '../pages/NotFound';
import AddCasting from '../pages/AddCasting';
import AddGallery from '../pages/AddGallery';
import AddImageGallery from '../pages/AddImageGallery';
import ModifyInfosVideo from '../pages/ModifyInfosVideo';
import ModifyVideoBanner from '../pages/ModifyVideoBanner';
import ModifyTitleBanner from '../pages/ModifyTitleBanner';

function App() {
  return (
    <div>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/add-image-video" element={<AddImageVideo />} />
        <Route path="/add-videos" element={<AddVideos />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/image-education" element={<ImageEducation />} />
        <Route path="/login" element={<Login />} />
        <Route path="/quote" element={<Quote />} />
        <Route path="/register" element={<Register />} />
        <Route path="/video-library" element={<VideoLibrary />} />
        <Route path="/video-library/:id" element={<VideoScreen />} />
        <Route path="/add-casting" element={<AddCasting />} />
        <Route path="/add-gallery" element={<AddGallery />} />
        <Route path="/add-image-gallery" element={<AddImageGallery />} />
        <Route path="/modify-video-infos" element={<ModifyInfosVideo />} />
        <Route path="/modify-videobanner" element={<ModifyVideoBanner />} />
        <Route path="/modify-titlebanner" element={<ModifyTitleBanner />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
