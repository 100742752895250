import React from 'react';
import Banner from '../components/Banner';
import Footer from '../components/Footer';
import { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/AddImageVideo.css';
import { apiUrl } from '../datas/apiUrl';

function AddImageVideo() {
  const titlePage = 'Image de couverture';
  const titleTextPage = 'Ici ajoutez une image de couverture à une vidéo';

  const [videos, setVideos] = useState([]);
  const [videoId, setVideoId] = useState('');
  const [file, setFile] = useState('');

  useEffect(() => {
    const url = `${apiUrl}/videos`;
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        // console.log(data);
        setVideos(data);
      });
  }, []);

  const clearForm = () => {
    document.getElementById('add-image-form').reset();
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('file', new File([file], 'image'));
    axios({
      url: `${apiUrl}/videos/${videoId}/image`,
      method: 'POST',
      data: formData,

      headers: {
        Accept: 'Application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + window.localStorage.getItem('Bearer'),
      },
    })
      .then(function (response) {
        //handle success
        // console.log(response);
        clearForm();
        alert("L'image a bien été enregistré");
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };

  const option = videos.map(({ groupName, title, id }) => (
    <option value={id}>
      {groupName}: {title}
    </option>
  ));

  return (
    <div>
      <Banner title={titlePage} titleText={titleTextPage} />
      <div className="addImageVideo">
        <h1 className="text-addImageVideo">Test envoi d'une image</h1>
        <form
          id="add-image-form"
          encType="multipart/form-data"
          onSubmit={handleFormSubmit}
          className="form addImageVideo-form"
        >
          <select
            class="form-select"
            aria-label="Default select example"
            onChange={(e) => setVideoId(e.target.value)}
          >
            <option value="">Sélectionnez un titre</option>
            {option}
          </select>
          <label>Image de couverture</label>
          <input
            type="file"
            name="file"
            onChange={(e) => setFile(e.target.files[0])}
          />
          <input className="btn-dark" type="submit" value="Envoyer" />
        </form>
        <Footer />
      </div>
    </div>
  );
}

export default AddImageVideo;
