import React from 'react';
import Banner from '../components/Banner';
import Footer from '../components/Footer';
import { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/AddVideo.css';
import { apiUrl } from '../datas/apiUrl';
import { useNavigate } from 'react-router-dom';

function AddVideos() {
  const titlePage = 'Ajout De Liens Vidéos';
  const titleTextPage =
    'Entrez les infos necessaire au bon enregistrement des liens des vidéos';

  const [groupName, setGroupName] = useState('');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [coverDescription, setCoverDescription] = useState('');
  const [embedId, setEmbedId] = useState('');
  const [duration, setDuration] = useState('');
  const [releaseDate, setReleaseDate] = useState('');
  const [groupLink, setGroupLink] = useState('');

  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState('');

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (window.localStorage.getItem('Bearer')) {
      // Dans le cas où il y a un token
      const url = `${apiUrl}/videos`;
      axios
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          console.log(data);
        });
      console.log('Connecté');
      setIsLoggedIn(true);
      axios
        .get(`${apiUrl}/categories`)
        .then((response) => response.data)
        .then((data) => {
          // console.log(data);
          setCategories(data);
        })
        .then((data) => {
          console.log(data);
        });
    } else {
      // Dans le cas où on n'a pas de token
      console.log('Non connecté');
      setIsLoggedIn(false);
    }
  }, []);

  const optionCategory = categories.map(({ id, name }) => (
    <option value={id}>
      {id}: {name}
    </option>
  ));

  const navigate = useNavigate();

  const clearForm = () => {
    document.getElementById('add-video-form').reset();
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        Accept: 'Application/json',
        Authorization: 'Bearer ' + window.localStorage.getItem('Bearer'),
      },
    };
    const bodyParameters = {
      groupName: groupName,
      title: title,
      duration: duration,
      releaseDate: releaseDate,
      groupLink: groupLink,
      slug: (groupName + title)
        .replace(/[^a-zA-Z ]/g, '')
        .replace(/[' ']/g, '-')
        .replace(/-+/g, '-')
        .toLowerCase(),
      content: content,
      coverId: (title + groupName)
        .replace(/[^a-zA-Z ]/g, '')
        .replace(/[' ']/g, '-')
        .replace(/-+/g, '-')
        .toLowerCase(),
      coverDescription: coverDescription,
      embedId: embedId.split('/').pop(),
      category: `/api/categories/${categoryId}`,
    };
    axios
      .post(`${apiUrl}/videos`, bodyParameters, config)
      .then(function (response) {
        //handle success
        // console.log(response);
        clearForm();
        alert(
          "la vidéo a bien été enregistré N'OUBLIE PAS D'AJOUTER UNE IMAGE DE COUVERTURE!! LE BOUTTON EST JUSTE EN BAS DE LA PAGE !!"
        );
        navigate('/add-image-video');
      })
      .catch(function (error) {
        //handle error
        console.log(error.response);
        alert("La vidéo n'a pas été enregistrée");
      });
  };

  if (isLoggedIn) {
    return (
      <div>
        <Banner title={titlePage} titleText={titleTextPage} />
        <div className="addVideo">
          <h2 className="textAddVideo">Formulaire vidéo</h2>
          <form
            onSubmit={handleFormSubmit}
            id="add-video-form"
            className="form"
          >
            <select
              class="form-select"
              aria-label="Default select example"
              onChange={(e) => setCategoryId(e.target.value)}
              required
            >
              <option value="">Sélectionnez une catégorie</option>
              {optionCategory}
            </select>

            <label>Nom du groupe ou de l'organimse</label>
            <input
              type="text"
              name="groupName"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              required
            />

            <label>Titre</label>
            <input
              type="text"
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />

            <label>Durée</label>
            <input
              type="text"
              name="title"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              required
            />

            <label>Date de sortie</label>
            <input
              type="text"
              name="title"
              value={releaseDate}
              onChange={(e) => setReleaseDate(e.target.value)}
              required
            />

            <label>Lien vers le site du groupe/organisme</label>
            <input
              type="text"
              name="title"
              value={groupLink}
              onChange={(e) => setGroupLink(e.target.value)}
            />

            <label>Description du titre</label>
            <textarea
              type="text"
              name="content"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              required
            ></textarea>

            <label>Description de l'image de couverture</label>
            <input
              type="text"
              name="coverDescription"
              value={coverDescription}
              onChange={(e) => setCoverDescription(e.target.value)}
              required
            />

            <label>Code de la vidéo</label>
            <input
              type="text"
              name="embedId"
              value={embedId}
              onChange={(e) => setEmbedId(e.target.value)}
              required
            />

            <input type="submit" value="Ajouter la vidéo" />
          </form>
          {/* <button onClick={getCategory()}>test</button> */}
        </div>
        <h3 className="textAddVideo">
          <button>
            <a
              href="add-image-video"
              className="accueil-menu-title-href addImage-title-color"
            >
              Ajouter une image de couverture à une vidéo
            </a>
          </button>
        </h3>
        <Footer />
      </div>
    );
  } else {
    return (
      <div>
        <Banner title={titlePage} titleText={titleTextPage} />
        <div className="addVideo">
          <h1 className="textAddVideo">
            Vous n'avez pas accés à l'espace administration. <br />
            Veuillez vous connecter.
          </h1>
        </div>
        <Footer />
      </div>
    );
  }
}

export default AddVideos;
