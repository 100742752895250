import React from 'react';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/button';
import '../styles/BandList.css';
// import pictoWebN from '../assets/picto/web-noir.png';
import pictoWebB from '../assets/picto/web-blanc.png';
// import pictoCamN from '../assets/picto/camera-noir.png';
import pictoCamB from '../assets/picto/camera-blanc.png';
// import pictoClapN from '../assets/picto/clap-noir.png';
import pictoClapB from '../assets/picto/clap-blanc.png';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { api2Url, api3Url } from '../datas/apiUrl';
import imgDefault from '../assets/img/imgDefault.jpg';

function BandList({
  // gName,
  categoryUri,
  content,
  cover,
  coverDescription,
  coverId,
  embedId,
  groupName,
  id,
  title,
  slug,
  // indexForBgi,
  groupLink,
  duration,
  releaseDate,
}) {
  const [categoryName, setCategoryName] = useState('');
  useEffect(() => {
    let isMounted = true;
    axios
      .get(`${api3Url}${categoryUri}`)
      .then((response) => response.data)
      .then((data) => {
        if (isMounted) {
          setCategoryName(data.name);
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
    return () => {
      isMounted = false;
    };
  }, [categoryUri]);

  const months = [
    'Janvier',
    'Fevrier',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Decembre',
  ];

  function getDate(date) {
    // isNaN(day) ? console.log('return ça va pas') : console.log('return ça va');
    const newDate = new Date(date);
    const day = newDate.getDate();
    const month = newDate.getMonth();
    const year = newDate.getFullYear();
    if (isNaN(day) && isNaN(day) && isNaN(day)) {
      return 'N/C';
    }
    return `${date.length === 7 ? '' : day} ${months[month]} ${year}`;
  }

  // if (gName === groupName) {
  return (
    <div className="bandList-frame">
      <div className="bandList-frame-allcover">
        <div className="bandList-link-frame">
          <p className="groupName">{groupName}</p>
          <h2>
            <Link
              className="bandList-link-title"
              // className={`bandList-link-title ${
              //   indexForBgi === 0 ? 'aBlack' : 'aWhite'
              // }`}
              to={`/video-library/${slug}`}
              state={{ id: id }}
            >
              {title}
            </Link>
          </h2>
        </div>

        <div id={coverId} className="mdr-clip-img">
          {!cover ? <p>Pas d'image disponible pour le moment</p> : null}
          <Link to={`/video-library/${slug}`} state={{ id: id }}>
            <img
              src={cover ? `${api2Url}${cover}` : `${imgDefault}`}
              className={`d-block w-100 ${cover ? '' : 'coverImg'}`}
              alt={
                cover !== undefined && cover !== null ? coverDescription : title
              }
              data={cover ? '' : groupName}
            />
          </Link>
        </div>
        <div className="picto-frame">
          <div className="bandList-picto">
            <img className="picto" src={pictoCamB} alt="camera" />
            <p>{categoryName}</p>
          </div>
          <div className="bandList-picto">
            <img className="picto" src={pictoClapB} alt="clap de cinéma" />
            {/* {new Date(releaseDate).toLocaleDateString('fr')} */}
            <p>{getDate(releaseDate)}</p>
          </div>
          <div className="bandList-picto">
            {groupLink ? (
              <>
                <img className="picto" src={pictoWebB} alt="globe web" />
                <div>
                  <a
                    href={groupLink}
                    // className={indexForBgi === 0 ? 'aBlack' : 'aWhite'}
                    className="aWhite"
                  >
                    Découvrir leur univers...
                  </a>
                </div>
              </>
            ) : (
              <div className="noPicto"></div>
            )}
          </div>
          <p className="bandList-excerpt-content">
            {content.replace(/\\/g, '')}
          </p>
        </div>
      </div>
    </div>
  );
  // } else {
  //   return <div className="none">{/* NOTHING */}</div>;
  // }
}

export default BandList;
