import React from 'react';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/button';
import logoGif from '../../src/assets/logos/logo.svg';
import rgLoader from '../../src/assets/img/rgLoader.gif';

function VideoPlayable({ embedId }) {
  const regex = /[a-zA-Z]/;
  return (
    <div className="container">
      {embedId ? (
        <div className="embed-video-display" id={embedId}>
          <iframe
            className="video"
            width="853"
            height="480"
            src={
              embedId.match(regex)
                ? `https://www.youtube.com/embed/${embedId}`
                : `https://player.vimeo.com/video/${embedId}`
            }
            rel="0"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube video"
          />
        </div>
      ) : (
        <div className="loading">
          <h2>Chargement...</h2>
          <img
            src={rgLoader}
            alt="cercle de chargement"
            height={150}
            width={150}
          />
          <img
            src={logoGif}
            alt="logo minees de rien"
            height={250}
            width={250}
          />
        </div>
      )}
    </div>
  );
}

export default VideoPlayable;
