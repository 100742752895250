import React, { useState } from 'react';
import '../styles/NavBar.css';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/offcanvas';
import logo from '../assets/logos/logo.svg';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

/* When the user scrolls down, hide the navbar. When the user scrolls up, show the navbar */

function NavBar() {
  const [screenWidth, setScreenWidth] = useState(0);
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  if (screenWidth > 670) {
    let prevScrollpos = window.pageYOffset;
    window.onscroll = function () {
      let currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        document.getElementById('navbar').style.width = '325px';
        document.getElementById('nav-brand').style.opacity = '100';
        document.getElementById('imgLogoAccueil').style.opacity = '100';
        document.getElementById('navbar').style.left = '40px';
      } else {
        document.getElementById('navbar').style.width = '80px';
        document.getElementById('navbar').style.left = '15px';
        document.getElementById('nav-brand').style.opacity = '0';
        document.getElementById('imgLogoAccueil').style.opacity = '0';
      }
      prevScrollpos = currentScrollPos;
    };
  }
  useEffect(() => {
    setScreenWidth(window.screen.width);
    if (window.localStorage.getItem('Bearer')) {
      // Dans le cas où il y a un token
      console.log('connecté');
      // setIsLoggedIn(true);
    } else {
      // Dans le cas où on n'a pas de token
      console.log('Non connecté');
      // setIsLoggedIn(false);
    }
  }, [screenWidth]);

  return (
    <div className="fixed-top navbar-content" id="navbar">
      <nav className="navbar navbar-light bg-light bg-image">
        <div className="container-fluid">
          <div className="menu-hamburger-button">
            <div className="buttonAndBrand">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div>
                <p className="navbar-brand" to="#mdr-cine" id="nav-brand">
                  cinéma et création en pays minier
                </p>
              </div>
            </div>
            <div className="accueilLogo">
              <Link className="off-link" to="/">
                <img
                  src={logo}
                  alt="logo mines de rien"
                  className="accueilLogoImg"
                  id="imgLogoAccueil"
                />
              </Link>
            </div>
          </div>

          <div
            className="offcanvas offcanvas-start"
            tabIndex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            // data-bs-dismiss="offcanvas"
          >
            <div className="offcanvas-header">
              <h5
                className="offcanvas-title"
                id="offcanvasNavbarLabel"
                data-bs-dismiss="offcanvas"
              >
                <Link className="off-link" to="/">
                  Mines de Rien Cinéma
                </Link>
              </h5>
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                <li className="nav-item" data-bs-dismiss="offcanvas">
                  <Link
                    className="off-link active"
                    aria-current="page"
                    to="/video-library"
                  >
                    Voir nos créations
                  </Link>
                </li>
                <li className="nav-item" data-bs-dismiss="offcanvas">
                  <Link className="off-link" to="/image-education">
                    Éduquer à l’image
                  </Link>
                </li>
                <li className="nav-item" data-bs-dismiss="offcanvas">
                  <Link className="off-link" to="/About">
                    Découvrir notre univers
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="off-link dropdown-toggle"
                    to="/quote"
                    id="offcanvasNavbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                  >
                    Commander une vidéo
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="offcanvasNavbarDropdown"
                  >
                    <li data-bs-dismiss="offcanvas disabled">
                      <Link className="dropdown-item disabled" to="/quote">
                        Demander un devis
                      </Link>
                    </li>
                    <li data-bs-dismiss="offcanvas disabled">
                      <Link className="dropdown-item disabled" to="/quote">
                        Suivre une demande
                      </Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li data-bs-dismiss="offcanvas disabled">
                      <Link className="dropdown-item disabled" to="/quote">
                        Mes devis
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="offcanvas-contact-logo-frame">
              <div className="offcanvas-contact" data-bs-dismiss="offcanvas">
                <Link className="off-link" to="/admin">
                  Espace administration
                </Link>
              </div>
              <div className="offcanvas-logo" data-bs-dismiss="offcanvas">
                <img src={logo} alt="logo mines de rien cinéma" />
              </div>
              <div className="offcanvas-contact" data-bs-dismiss="offcanvas">
                <Link className="contact-link" to="/contact">
                  Nous contacter
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
