import React from 'react';
import Banner from '../components/Banner';
import Footer from '../components/Footer';
import { useState } from 'react';
import axios from 'axios';
import '../styles/AddImageVideo.css';
import { apiUrl } from '../datas/apiUrl';
import { useNavigate } from 'react-router-dom';

function ModifyVideoBanner() {
  const titlePage = "Vidéo d'entête";
  const titleTextPage = "Ici changer la vidéo d'entête";

  //   const [videos, setVideos] = useState([]);
  //   const [videoId, setVideoId] = useState('');
  const [file, setFile] = useState('');

  //   useEffect(() => {
  //     const url = `${apiUrl}/videobanners`;
  //     axios
  //       .get(url)
  //       .then((response) => response.data)
  //       .then((data) => {
  //         // console.log(data);
  //         setVideos(data);
  //       });
  //   }, []);

  const navigate = useNavigate();

  const clearForm = () => {
    document.getElementById('add-image-form').reset();
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('file', new File([file], 'image'));
    axios({
      url: `${apiUrl}/videobanners/1/video`,
      method: 'POST',
      data: formData,

      headers: {
        Accept: 'Application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + window.localStorage.getItem('Bearer'),
      },
    })
      .then(function (response) {
        //handle success
        clearForm();
        alert('La vidéo a bien été modifié');
        navigate('/admin');
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };

  //   const option = videos.map(({ groupName, title, id }) => (
  //     <option value={id}>
  //       {groupName}: {title}
  //     </option>
  //   ));

  return (
    <div>
      <Banner title={titlePage} titleText={titleTextPage} />
      <div className="addImageVideo">
        <h1 className="text-addImageVideo">
          Changer la vidéo d'entête de la page "réalisations"
        </h1>
        <form
          id="add-image-form"
          encType="multipart/form-data"
          onSubmit={handleFormSubmit}
          className="form addImageVideo-form"
        >
          {/* <select
            class="form-select"
            aria-label="Default select example"
            onChange={(e) => setVideoId(e.target.value)}
          >
            <option value="">Sélectionnez un titre</option>
            {option}
          </select> */}
          <label>Vidéo d'entête</label>
          <input
            type="file"
            name="file"
            onChange={(e) => setFile(e.target.files[0])}
          />
          <input className="btn-dark" type="submit" value="Envoyer" />
        </form>
        <Footer />
      </div>
    </div>
  );
}

export default ModifyVideoBanner;
