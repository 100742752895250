import React from 'react';
import Banner from '../components/Banner';
import Footer from '../components/Footer';
import { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/AddImageVideo.css';
import { apiUrl } from '../datas/apiUrl';

function AddImageGallery() {
  const titlePage = 'Images galleries';
  const titleTextPage = "Ici ajoutez une image pour la gallerie d'une vidéo";

  const [galleries, setGalleries] = useState([]);
  const [galleryId, setGalleryId] = useState('');
  const [file, setFile] = useState('');

  useEffect(() => {
    const url = `${apiUrl}/galleries`;
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        // console.log(data);
        setGalleries(data);
      });
  }, []);
  // console.log('le setGalleries dans useEffect: ', galleries);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('file', new File([file], 'image'));
    axios({
      url: `${apiUrl}/galleries/${galleryId}/image`,
      method: 'POST',
      data: formData,

      headers: {
        Accept: 'Application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + window.localStorage.getItem('Bearer'),
      },
    })
      .then(function (response) {
        //handle success
        // console.log(response);
        setFile('');
        alert('La photo a bien été enregistré');
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };

  const optionGallery = galleries.map(({ name, id, description }) => (
    <option value={id}>
      {name} - {description}
    </option>
  ));

  return (
    <div>
      <Banner title={titlePage} titleText={titleTextPage} />
      <div className="addImageVideo">
        <h1 className="text-addImageVideo">Test envoi d'une image</h1>
        <form
          encType="multipart/form-data"
          onSubmit={handleFormSubmit}
          className="form addImageVideo-form"
        >
          <select
            class="form-select"
            aria-label="Default select example"
            onChange={(e) => setGalleryId(e.target.value)}
          >
            <option value="">
              Sélectionnez le titre pour lequel voulez ajouter une photo
            </option>
            {optionGallery}
          </select>
          <label>Importez la photo</label>
          <input
            type="file"
            name="file"
            onChange={(e) => setFile(e.target.files[0])}
          />
          <input className="btn-dark" type="submit" value="Envoyer" />
        </form>
        <Footer />
      </div>
    </div>
  );
}

export default AddImageGallery;
