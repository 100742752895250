import React from 'react';
import Footer from '../components/Footer';
import Banner from '../components/Banner';
import { useEffect } from 'react';
import { apiUrl } from '../datas/apiUrl';
import axios from 'axios';

import '../styles/ImageEducation.css';

function ImageEducation() {
  // const [visitsToSend, setVisitsToSend] = useState(0);

  useEffect(() => {
    let imageEducation;
    let total;
    axios
      .get(`${apiUrl}/counter_visits/1`)
      .then((response) => response.data)
      .then((data) => {
        // console.log('le data pour le visitToSend avant tout changement: ', data);
        // setVisitsToSend(data);
        imageEducation = data.imageEducationVisits;
        total = data.totalVisits;
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        };
        const bodyParameters = {
          totalVisits: total + 1,
          imageEducationVisits: imageEducation + 1,
        };
        axios
          .put(`${apiUrl}/counter_visits/1`, bodyParameters, config)
          .then(function (response) {
            //handle success
            // console.log(response);
            // setVisitsToSend({
            //   imageEducationVisits: bodyParameters.imageEducationVisits,
            //   totalVisits: bodyParameters.totalVisits
            // });
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, []);

  const title = 'Éduquer à l’image';
  const titleText = '';

  return (
    <div className="About">
      {/* <p style={{...{width: '100%'}, ...{color: 'yellow'}, ...{position: 'absolute'}, ...{textAlign: 'right'}, ...{paddingRight: '15px'}, ...{display: 'inline-block'}}}>Toutes les pages du sites ont été vu au total <span>{visitsToSend.totalVisits}</span> fois. Et cette page a été vu <span>{visitsToSend.imageEducationVisits}</span> fois</p> */}
      <Banner title={title} titleText={titleText} />

      <div className="About-text-frame">
        <div className="textAbout">
          {/* <h3 className='about-h3-title'>Filmer l’underground minier</h3> */}
          <div className="about-bloc-para">
            <p className="about-p-text">
              Par le biais d'ateliers pédagogiques et de stages d’initiation,
              nous souhaitons faire découvrir aux publics du territoire les
              codes et le monde du cinéma.
              <br />
              <br />
              La dimension pédagogique de notre approche du cinéma est pour nous
              essentielle. Le cinéma est un outil très efficace d’expression
              personnelle et d’émancipation. Raconter des histoires, prêter sa
              peau à un autre personnage ou participer à une aventure de
              création collective sont autant d’expériences essentielles pour
              s’affirmer, gagner en confiance et en autonomie ou encore prendre
              un peu plus conscience de sa place dans le monde et auprès des
              autres. Surtout, ce sont les codes de tout un pan de la culture
              mondiale qui peuvent être accaparés, contribuant ainsi à réduire
              un tant soit peu les inégalités d’accès à la culture, à sa
              compréhension et à sa pratique.
              <br />
              <br />
              Notre démarche pédagogique repose avant tout sur la stimulation de
              l’imaginaire des personnes participantes. Cette stimulation passe
              par la création d’un cadre propice au respect mutuel et l’écoute
              des émotions et des pensées de chacun, qui seront partagées pour
              être transformées en images et en sons.
              <br />
              <br />
              Nos champs de compétences sont aussi variés que nos centres
              d’intérêt : nous pouvons travailler divers formats (documentaire,
              court-métrage, clip expérimental, conte..) à destination de
              différents publics et avec un volume horaire adaptable à vos
              ressources et à vos attentes.
              <br />
              <br />
              Pour découvrir le fond de notre propos : Série de vidéos Fauteuils
              Rouges et boîte noire
              <br />
              <br />
              Nos expériences : Animation de deux ateliers hebdomadaires à
              l’Escapade d’Hénin-Beaumont depuis septembre 2021
              <br />
              <br />
              MONTAGE du court-métrage Et si c’était vrai ?, par l’atelier
              théâtre de l’hôpital de jour l’Escale, lauréat du prix “meilleur
              film amateur” au festival des colporteurs d’images de l’escapade
              (Hénin-Beaumont)
              <br />
              <br />
              ATELIER de création d’un court-métrage, Harcèlement, avec les
              élèves du collège Gérard Philipe d’Hénin-Beaumont
              <br />
              <br />
              ANIMATION d’un atelier d’écriture à destination d’un groupe
              d’enfants, pour Gocolo (jusqu’en 2019)
              <br />
              <br />
              ANIMATION d’ateliers pédagogiques à destination d’élèves de
              collège : jeux de rôle, cinéma, écriture, création d’imaginaire,
              dessin (jusqu’à aujourd’hui)
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default ImageEducation;
