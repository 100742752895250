import { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/Register.css';
import SchowHideIcon from '../assets/img/show-hide-icon-28.jpg';
import { apiUrl } from '../datas/apiUrl';

function Register() {
  const [firstname, setFirstname] = useState('');
  const [firstnameValid, setFirstnameValid] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState('');
  const [password, setPassword] = useState('');
  const [passwordValid, setPasswordValid] = useState('');
  const [retryPass, setRetryPass] = useState('');
  const [identicPass, setIdenticPass] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showHide, setShowHide] = useState(false);
  const [zipCode, setZipCode] = useState('');
  const [zipCodeValid, setZipCodeValid] = useState('');
  const [town, setTown] = useState('');

  const isEmail = () => {
    let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]{2,}\.[a-z]{2,4}$/;

    if (email.match(regex)) {
      setEmailValid('is-valid');
      return true;
    } else {
      setEmailValid('is-invalid');
      return false;
    }
  };

  const samePassword = () => {
    if (password === retryPass) {
      setIdenticPass('is-valid');
      return true;
    } else {
      setIdenticPass('is-invalid');
      return false;
    }
  };

  const isFirstname = () => {
    if (firstname === '') {
      setFirstnameValid('is-invalid');
      return false;
    } else {
      setFirstnameValid('is-valid');
      return true;
    }
  };

  const isZipCode = () => {
    let regex = /^[0-9]{5}$/;
    if (zipCode.match(regex) | (zipCode === '')) {
      setZipCodeValid('');
      return true;
    } else {
      setZipCodeValid('is-invalid');
      return false;
    }
  };

  const isPassword = () => {
    let regex = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{10,})/;

    if (password.match(regex) && samePassword()) {
      setPasswordValid('is-valid');
      return true;
    } else if (password.match(regex) === false) {
      setPasswordValid('is-invalid');
      return false;
    } else {
      samePassword();
      setPasswordValid('is-invalid');
      return false;
    }
  };

  useEffect(() => {
    if (window.localStorage.getItem('Bearer')) {
      // Dans le cas où il y a un token
      axios
        .get(`${apiUrl}/users`, {
          headers: {
            Accept: 'Application/json',
            Authorization: 'Bearer ' + window.localStorage.getItem('Bearer'),
          },
        })
        .then((response) => response.data)
        .then((data) => {
          // console.log(data);
          // setUsersDatas(data);
          console.log('Connecté');
          setIsLoggedIn(true);
        })
        .catch((error) => {
          console.log(error.response);
        });
    } else {
      // Dans le cas où on n'a pas de token
      console.log('Non connecté');
      setIsLoggedIn(false);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isPassword() && isEmail() && isZipCode() && isFirstname()) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + window.localStorage.getItem('Bearer'),
        },
      };
      const bodyParameters = {
        email: email,
        firstname: firstname,
        lastname: lastname,
        town: town,
        zipCode: Number(zipCode),
        password: password,
      };
      axios
        .post(`${apiUrl}/users`, bodyParameters, config)
        .then(function (response) {
          //handle success
          // console.log(response);
          setFirstname('');
          setLastname('');
          setEmail('');
          setPassword('');
          setRetryPass('');
          setZipCode('');
          setTown('');

          alert("Bravo! l'inscription est enregistré.");
        })
        .catch(function (response) {
          //handle error
          console.log(response);
          alert(
            "Désolé... Vous ne pouvez pas effectuer d'inscription. Seul un administrateur le peux. Merci de nous laisser un message depuis la page contact."
          );
        });
    } else {
      console.log('Arcomminche...');
    }
  };

  // const logout = () => {
  //   window.localStorage.removeItem("Bearer");
  //   setIsLoggedIn(false);
  // };

  const hideShow = () => {
    if (!showHide) setShowHide(true);
    else setShowHide(false);
  };

  // if (isLoggedIn) {
  //   return (
  //     <div className="register">
  //       <h1>Je suis connecté!</h1>
  //       <button onClick={logout}>Se déconnecter</button>
  //     </div>
  //   );
  // } else
  return (
    <div className="register">
      <h1 className="register-title">
        {isLoggedIn
          ? 'Vous pouvez inscrire un nouvelle utilisateur ici'
          : "Il faut d'abord vous identifier avant d'insrire un nouvel utilisateur"}
      </h1>

      <div className="register-form">
        <form className="row g-3 login-form" onSubmit={handleSubmit}>
          <div className="col-md-4">
            <label for="validationServer01" className="form-label">
              Prénom
            </label>
            <input
              type="text"
              className={`form-control ${firstnameValid}`}
              id="validationServer01"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
            />
            <div class="invalid-feedback">
              Veuillez entrer un nom de contact
            </div>
          </div>
          <div className="col-md-4">
            <label for="validationServer02" className="form-label">
              Nom
            </label>
            <input
              type="text"
              className="form-control"
              id="validationServer02"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
            />
          </div>
          <div className="col-md-4">
            <label for="validationServerEmail" className="form-label">
              Addresse mail
            </label>
            <div className="input-group has-validation">
              <span className="input-group-text" id="inputGroupPrepend3">
                @
              </span>
              <input
                type="text"
                className={`form-control ${emailValid}`}
                id="validationServerEmail"
                aria-describedby="inputGroupPrepend3 validationServerEmailFeedback"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <div
                id="validationServerEmailFeedback"
                className="invalid-feedback"
              >
                Format d'addresse mail incorrect
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <label for="validationServer03" className="form-label">
              Ville
            </label>
            <input
              type="text"
              className="form-control"
              id="validationServer03"
              value={town}
              onChange={(e) => setTown(e.target.value)}
            />
          </div>
          <div className="col-md-3">
            <label for="validationServer05" className="form-label">
              Code Postal
            </label>
            <input
              type="text"
              className={`form-control ${zipCodeValid}`}
              id="validationServer05"
              aria-describedby="validationServer05Feedback"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
            />
            <div id="validationServer05Feedback" className="invalid-feedback">
              Veuillez entrer un code postal correct
            </div>
          </div>
          <div className="col-md-4">
            <label for="validationServerPsw" className="form-label">
              Mot de passe
            </label>
            <div className="input-group has-validation">
              <input
                type={!showHide ? 'password' : 'text'}
                className={`form-control ${passwordValid}`}
                id="validationServerPsw"
                aria-describedby="inputGroupPrepend3 validationServerPswFeedback"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span className="input-group-text" id="inputGroupPrepend3">
                <img
                  src={SchowHideIcon}
                  alt="icône show/hide"
                  style={{ width: '22px' }}
                  onClick={hideShow}
                />
              </span>
              <div
                id="validationServerPswFeedback"
                className="invalid-feedback"
              >
                <span>
                  Le mot de passe ne correspond pas aux critères de sécurité
                </span>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <label for="validationPsw2" className="form-label">
              Resaisissez votre mot de passe
            </label>
            <div className="input-group has-validation">
              <input
                type={!showHide ? 'password' : 'text'}
                className={`form-control ${identicPass}`}
                id="validationPsw2"
                aria-describedby="validationPsw2Feedback"
                value={retryPass}
                onChange={(e) => setRetryPass(e.target.value)}
                required
              />
              <span className="input-group-text" id="inputGroupPrepend3">
                <img
                  src={SchowHideIcon}
                  alt="icône show/hide"
                  style={{ width: '22px' }}
                  onClick={hideShow}
                />
              </span>
              <div
                id="validationServerPswFeedback"
                className="invalid-feedback"
              >
                <span>Les mots de passe saisi ne sont pas identique</span>
              </div>
            </div>
          </div>

          <div className="col-12">
            <button className="btn btn-dark" type="submit">
              ENVOYER
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Register;
