export const apiUrl =
  process.env.REACT_APP_API_URL ?? 'http://localhost:8000/api';

export const api2Url =
  process.env.REACT_APP_API2_URL ?? 'http://localhost:8000';

export const api3Url =
  process.env.REACT_APP_API3_URL ?? 'http://localhost:8000';

export const frontUrl =
  process.env.REACT_APP_FRONT_URL ?? 'http://localhost:3000';

export const uri = process.env.REACT_APP_URI ?? '/api';
