import React, { useState } from 'react';
import Banner from '../components/Banner';
import Footer from '../components/Footer';
import '../styles/Contact.css';
import { useEffect } from 'react';
import axios from 'axios';
import { apiUrl } from '../datas/apiUrl';

function Contact() {
  // const [visitsToSend, setVisitsToSend] = useState(0);

  useEffect(() => {
    let contact;
    let total;
    axios
      .get(`${apiUrl}/counter_visits/1`)
      .then((response) => response.data)
      .then((data) => {
        // console.log(
        //   'le data pour le visitToSend avant tout changement: ',
        //   data
        // );
        // setVisitsToSend(data);
        contact = data.contactVisits;
        total = data.totalVisits;
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        };
        const bodyParameters = {
          totalVisits: total + 1,
          contactVisits: contact + 1,
        };
        axios
          .put(`${apiUrl}/counter_visits/1`, bodyParameters, config)
          .then(function (response) {
            //handle success
            // console.log(response);
            // setVisitsToSend({
            //   contactVisits: bodyParameters.contactVisits,
            //   totalVisits: bodyParameters.totalVisits
            // });
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, []);

  const titlePage = 'Contact';
  const titleTextPage = "N'hésitez pas à nous laisser vos coordonnées";

  const [name, setName] = useState('');
  const [nameValid, setNameValid] = useState('');
  const [company, setCompany] = useState('');
  const [phone, setPhone] = useState('');
  const [mail, setMail] = useState('');
  const [mailValid, setMailValid] = useState('');
  const [content, setContent] = useState('');
  const [contentValid, setContentValid] = useState('');

  const isMail = () => {
    let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]{2,}\.[a-z]{2,4}$/;

    if (mail.match(regex)) {
      setMailValid('is-valid');
      return true;
    } else {
      setMailValid('is-invalid');
      return false;
    }
  };

  const isName = () => {
    if (name === '') {
      setNameValid('is-invalid');
      return false;
    } else {
      setNameValid('is-valid');
      return true;
    }
  };

  const isContent = () => {
    if (content === '') {
      setContentValid('is-invalid');
      return false;
    } else {
      setContentValid('is-valid');
      return true;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isName() && isMail() && isContent()) {
      axios
        .post(`${apiUrl}/contacts`, {
          name: name,
          company: company,
          phone: phone,
          mail: mail,
          content: content,

          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
        .then(function (response) {
          //handle success
          // console.log(response);
          setName('');
          setCompany('');
          setPhone('');
          setMail('');
          setContent('');
          setNameValid('');
          setMailValid('');
          setContentValid('');

          alert(
            'Le message à bien été envoyé. On vous recontact au plus vite!'
          );
        })
        .catch(function (error) {
          //handle error
          console.log(error.response.data);
        });
    } else {
      console.log('Arcomminche...');
    }
  };
  // const name = "minesderien.cinema";
  // const domain = "gmail.com";

  return (
    <div>
      <p
        style={{
          ...{ width: '100%' },
          ...{ color: 'yellow' },
          ...{ position: 'absolute' },
          ...{ textAlign: 'right' },
          ...{ paddingRight: '15px' },
          ...{ display: 'inline-block' },
        }}
      >
        {/* Toutes les pages du sites ont été vu au total{' '}
        <span>{visitsToSend.totalVisits}</span> fois. Et cette page a été vu{' '}
        <span>{visitsToSend.contactVisits}</span> fois */}
      </p>
      <Banner title={titlePage} titleText={titleTextPage} />
      <div className="contact">
        <h2 className="textContact">Formulaire de contact</h2>

        <div className="contact-form">
          <h2>Contactez-nous</h2>
          {/* <h2 className='contact-mail-title'>Mail</h2>
          <p className='contact-mail'><a href={`mailto:${name}@${domain}`}>{name}&#123;at&#125;{domain}</a></p> */}

          <form className="col contact-form-col" onSubmit={handleSubmit}>
            <div className="col-md-4">
              <label htmlFor="name" className="form-label">
                Nom
              </label>
              <div className="input-group has-validation">
                <input
                  className={`form-control ${nameValid}`}
                  type="text"
                  id="name"
                  name="name"
                  onChange={(e) => setName(e.target.value)}
                  placeholder="nom *"
                  value={name}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="col-md-4">
              <label htmlFor="company" className="form-label">
                Groupe
              </label>
              <div className="input-group has-validation">
                <input
                  className="form-control"
                  type="text"
                  id="company"
                  name="company"
                  onChange={(e) => setCompany(e.target.value)}
                  placeholder="société"
                  value={company}
                />
              </div>
            </div>
            <div className="col-md-4">
              <label htmlFor="validationServer02" className="form-label">
                Numéro de téléphone
              </label>
              <div className="input-group has-validation">
                <input
                  className="form-control"
                  type="text"
                  id="phone"
                  name="phone"
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="téléphone"
                  value={phone}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="email-content">
                <label htmlFor="validationMail">Addresse mail</label>
                <div className="input-group has-validation">
                  {/* <span className="input-group-text">@</span> */}
                  <input
                    className={`form-control ${mailValid}`}
                    type="mail"
                    id="validationMail"
                    name="mail"
                    onChange={(e) => setMail(e.target.value)}
                    placeholder="email *"
                    value={mail}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <label htmlFor="content" className="form-label">
                Laissez un message
              </label>
              <textarea
                className={`form-control ${contentValid}`}
                id="message"
                name="content"
                onChange={(e) => setContent(e.target.value)}
                placeholder="message *"
                value={content}
              />
            </div>
            <div className="form-message"></div>
            <div className="col-12 btn-form">
              <button className="btn btn-dark" type="submit">
                ENVOYER
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
