import React from 'react';
import Banner from '../components/Banner';
import Footer from '../components/Footer';
import { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/AddImageVideo.css';
import { apiUrl } from '../datas/apiUrl';

function AddGallery() {
  const titlePage = "Ajout D'infos Photo";
  const titleTextPage =
    "Entrez les infos necessaire pour l'enregistrement d'une photo";

  // Ce qu'il faut pour AddGallery plus rajouté image depuis AddImageGallery
  // {
  //     "name": "string",
  //     "description": "string",
  //     "altDescription": "string",
  //     "video": "string"
  //   }

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [altDescription, setAltDescription] = useState('');

  const [videos, setVideos] = useState([]);
  const [videoId, setVideoId] = useState('');

  // const [galleries, setGalleries] = useState([]);
  // const [galleryId, setGalleryId] = useState("");
  const [file, setFile] = useState('');

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (window.localStorage.getItem('Bearer')) {
      // Dans le cas où il y a un token
      const url = `${apiUrl}/videos`;
      axios
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          // console.log(data);
          setVideos(data);
        });
      console.log('Connecté');
      setIsLoggedIn(true);
      axios
        .get(`${apiUrl}/galleries`)
        .then((response) => response.data)
        .then((data) => {
          console.log(data);
          // setGalleries(data);
        });
    } else {
      // Dans le cas où on n'a pas de token
      console.log('Non connecté');
      setIsLoggedIn(false);
    }
  }, []);

  const optionVideos = videos.map(({ id, groupName, title }) => (
    <option value={id}>
      {id}: {groupName} - {title}
    </option>
  ));

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'Application/json',
        Authorization: 'Bearer ' + window.localStorage.getItem('Bearer'),
      },
    };
    const bodyParameters = {
      name: name,
      description: description,
      altDescription: altDescription,
      video: `/api/videos/${videoId}`,
    };
    axios
      .post(`${apiUrl}/galleries`, bodyParameters, config)
      .then(function (response) {
        //handle success
        // console.log(response);
        const formData = new FormData();
        formData.append('file', new File([file], 'image'));
        axios({
          url: `${apiUrl}/galleries/${response.data.id}/image`,
          // url: `${apiUrl}/galleries/${galleryId}/image`,
          method: 'POST',
          data: formData,

          headers: {
            Accept: 'Application/json',
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + window.localStorage.getItem('Bearer'),
          },
        })
          .then(function (response) {
            //handle success
            // console.log(response);
            setFile('');
            alert('La photo a bien été enregistré');
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });
        setName('');
        setDescription('');
        setAltDescription('');
        alert('Les données ont bien été enregistré');
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };

  if (isLoggedIn) {
    return (
      <div>
        <Banner title={titlePage} titleText={titleTextPage} />
        <div className="addImageVideo">
          <h2 className="text-addImageVideo">Formulaire vidéo</h2>
          <form
            encType="multipart/form-data"
            onSubmit={handleFormSubmit}
            className="form addImageVideo-form"
          >
            <select
              class="form-select"
              aria-label="Default select example"
              onChange={(e) => setVideoId(e.target.value)}
            >
              <option value="">
                Sélectionnez une Vidéo d'ou a été tiré la photo
              </option>
              {optionVideos}
            </select>

            <label>Son titre</label>
            <input
              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <label>Sa petite histoire</label>
            <textarea
              type="text"
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>

            <label>Une petite description de ce qui figure sur la photo</label>
            <input
              type="text"
              name="altDescription"
              value={altDescription}
              onChange={(e) => setAltDescription(e.target.value)}
            />

            <label>Importez la photo</label>
            <input
              type="file"
              name="file"
              onChange={(e) => setFile(e.target.files[0])}
            />

            <input
              className="btn-dark"
              type="submit"
              value="Ajouter les infos de la photo"
            />
          </form>
        </div>
        {/* <h2 className="text-admin">
              <a href="add-image-gallery" className="accueil-menu-title-href">
                Ajouter une photo pour la gallerie d'une vidéo
              </a>
            </h2> */}
        <Footer />
      </div>
    );
  } else {
    return (
      <div>
        <Banner title={titlePage} titleText={titleTextPage} />
        <div className="addVideo">
          <h1 className="textAddVideo">
            Vous n'avez pas accés à l'espace administration. <br />
            Veuillez vous connecter.
          </h1>
        </div>
        <Footer />
      </div>
    );
  }
}

export default AddGallery;
