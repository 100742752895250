import '../styles/Banner.css';

function Banner({ title, tiText, titleText, userDatas }) {
  return (
    <div>
      <div className="bgi-banner">
        <div className="banner-text-frame">
          {userDatas !== undefined ? (
            <div>
              <h2>Bonjourno {userDatas.firstname} !!</h2>
              <p>Tu habites "{userDatas.town}" ! C'est cool !</p>
            </div>
          ) : (
            ''
          )}
          <h1>
            <strong>{title}</strong>
          </h1>
          <div className="banner-text-title">
            <p className="text-title">{tiText}</p>
            <p className="text-title">{titleText}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
