function Casting(teamArray) {
    let team = Object.values(teamArray)[0];
    // console.log('la team: ', team);
    let groupCard;

    if (team !== undefined) { 
      groupCard = team.map((member, index) => (
          <li key={index} className="list-groupCard">{member.firstname} ({member.jobTitle})</li>
      ))
    }

    // console.log('le groupCard: ', groupCard);
    // console.log('le teamArray.length ', teamArray.length);
    // console.log('le team.length ', team.length);

    
    if (team.length !== 0) {
    return (
      <div className="container">
        <div className="card">
          <p className="card-header">
            <strong>Equipe technique</strong>
          </p>
          <ul className="list-group list-group-flush">{groupCard ? groupCard : "Pas de données pour le moment"}</ul>
        </div>
      </div>
    )
  } else {
    return (
      <div className="container">
        <div className="card">
          <p className="card-header">
            <strong>Equipe technique</strong>
          </p>
        </div>
        <div className="card img-wrap">
          <p className='card-body'>Bientôt vous verrez qui à participé au tournage ! Promis !</p>
        </div>
      </div>
    )
  }
    
}
    
    
    export default Casting