export default function Synopsis({text, bgi}) {
    return (
      <div className="container">
        <div className="card img-wrap">
          <img
            src={
              bgi !== undefined && bgi !== null
                ? bgi
                : ""
            }
            alt=""
          />
          <p className="card-body">{text}</p>
        </div>
      </div>
    );
}
