import React from 'react';
import { useState, useEffect } from 'react';
import Banner from '../components/Banner';
import Footer from '../components/Footer';
import axios from 'axios';
import '../styles/Login.css';
import SchowHideIcon from '../assets/img/show-hide-icon-28.jpg';
import { apiUrl } from '../datas/apiUrl';
import { Navigate } from 'react-router-dom';

function Login() {
  useEffect(() => {
    if (window.localStorage.getItem('Bearer')) {
      // Dans le cas où il y a un token
      console.log('Connecté');
      setIsLoggedIn(true);
    } else {
      // Dans le cas où on n'a pas de token
      console.log('Non connecté');
      setIsLoggedIn(false);
    }
  }, []);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const titlePage = 'Se connecter';
  const titleTextPage = 'Entrez votre identifiant et votre mot de passe';

  const [showHide, setShowHide] = useState(false);

  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState('');
  const [password, setPassword] = useState('');

  const hideShow = () => {
    if (!showHide) setShowHide(true);
    else setShowHide(false);
  };

  const isEmail = () => {
    let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]{2,}\.[a-z]{2,4}$/;

    if (email.match(regex)) {
      setEmailValid('is-valid');
      return true;
    } else {
      setEmailValid('is-invalid');
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isEmail()) {
      axios
        .post(`${apiUrl}/login`, {
          username: email,
          password: password,

          headers: {
            Accept: 'Application/json',
            'Content-Type': 'Application/json',
          },
        })
        .then((res) => {
          //handle success
          // console.log('le resultat login res: ', res);
          window.localStorage.setItem('Bearer', res.data.token);
          setIsLoggedIn(true);
          setEmail('');
          setPassword('');
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    } else {
      alert('identifiant ou addresse mail incorrect');
      // console.log("identifiant ou addresse mail incorrect");
    }
  };

  if (isLoggedIn) {
    // return (
    //   <div className="loggedIn">
    //     <div className="loggedIn-frame">
    //       <h1>Vous êtes connecté !</h1>
    //       <button className="btn btn-dark" onClick={logout}>
    //         Se déconnecter
    //       </button>
    //     </div>
    //   </div>
    // );
    return <Navigate to="/admin" state={{ username: email }} />;
  } else {
    return (
      <div>
        <Banner title={titlePage} titleText={titleTextPage} />

        <div className="contact">
          <h1 className="textContact">Connection</h1>

          <form className="row g-3 login-form" onSubmit={handleSubmit}>
            <div className="col-md-4">
              <label htmlFor="validationServerEmail" className="form-label">
                Addresse mail
              </label>
              <div className="input-group has-validation">
                <span className="input-group-text" id="inputGroupPrepend3">
                  @
                </span>
                <input
                  type="text"
                  className={`form-control ${emailValid}`}
                  id="validationServerEmail"
                  aria-describedby="inputGroupPrepend3 validationServerEmailFeedback"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <div
                  id="validationServerEmailFeedback"
                  className="invalid-feedback"
                >
                  Format d'addresse mail incorrect
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <label htmlFor="validationServerPsw" className="form-label">
                Mot de passe
              </label>
              <div className="input-group has-validation">
                <input
                  type={!showHide ? 'password' : 'text'}
                  id="validationServerPsw"
                  aria-describedby="inputGroupPrepend3 validationServerPswFeedback"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <span className="input-group-text" id="inputGroupPrepend3">
                  <img
                    src={SchowHideIcon}
                    alt="icône show/hide"
                    style={{ width: '22px' }}
                    onClick={hideShow}
                  />
                </span>
                <div
                  id="validationServerPswFeedback"
                  className="invalid-feedback"
                >
                  <span>
                    Le mot de passe ne correspond pas aux critères de sécurité
                  </span>
                </div>
              </div>
              <div className="col-12">
                <button className="btn btn-dark" type="submit">
                  ENVOYER
                </button>
              </div>
            </div>
          </form>

          <Footer />
        </div>
      </div>
    );
  }
}

export default Login;
