import React from 'react';
import Footer from '../components/Footer';
import Banner from '../components/Banner';
import { useEffect } from 'react';
import { apiUrl } from '../datas/apiUrl';
import axios from 'axios';
// import CounterVisits from '../components/CounterVisits';

import '../styles/About.css';

function About() {
  // const [visitsToSend, setVisitsToSend] = useState(0);

  useEffect(() => {
    let about;
    let total;
    axios
      .get(`${apiUrl}/counter_visits/1`)
      .then((response) => response.data)
      .then((data) => {
        // console.log('le data pour le visitToSend avant tout changement: ', data);
        // setVisitsToSend(data);
        about = data.aboutVisits;
        total = data.totalVisits;
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        };
        const bodyParameters = {
          totalVisits: total + 1,
          aboutVisits: about + 1,
        };
        axios
          .put(`${apiUrl}/counter_visits/1`, bodyParameters, config)
          .then(function (response) {
            //handle success
            // console.log(response);
            // setVisitsToSend({
            //   aboutVisits: bodyParameters.aboutVisits,
            //   totalVisits: bodyParameters.totalVisits
            // });
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, []);

  const title = 'Découvrez notre univers';
  const titleText = '';

  return (
    <div className="About">
      {/* <CounterVisits pageName='about'/> */}
      {/* <p style={{...{width: '100%'}, ...{color: 'yellow'}, ...{position: 'absolute'}, ...{textAlign: 'right'}, ...{paddingRight: '15px'}, ...{display: 'inline-block'}}}>Toutes les pages du sites ont été vu au total <span>{visitsToSend.totalVisits}</span> fois. Et cette page a été vu <span>{visitsToSend.aboutVisits}</span> fois</p> */}
      <Banner title={title} titleText={titleText} />

      <div className="About-text-frame">
        <div className="textAbout">
          <h3 className="about-h3-title">Filmer l’underground minier</h3>
          <div className="about-bloc-para">
            <p className="about-p-text">
              C’est en constatant le manque d’imaginaires cinématographiques
              venant “d’ici” que nous avons créé le collectif Mines de Rien.
              Notre but : partager notre regard sur le Bassin minier, décor de
              notre enfance.
              <br />
              <br />
              Influencés par les vidéastes qui émergent sur internet, nous avons
              commencé par réaliser plusieurs vidéos présentant différents
              aspects historiques ou culturels de ce territoire, qu’il s’agisse
              des terrils, de la catastrophe de Courrières ou de la langue
              picarde.
              <br />
              <br />
              A partir de 2018, nous nous sommes lancés dans une démarche plus
              introspective, mettant en scène notre rapport personnel à cet
              endroit où les perspectives manquent, mais dont l’horizon lardé de
              briques et de terrils est baigné d’une nostalgie et d’une magie
              qu’il s’agit maintenant filmer.
              <br />
              <br />
              Depuis un premier clip pour le groupe Rivelaine, nous multiplions
              les tournages et les collaborations, tentant à chaque fois de
              capturer un petit bout de rêve au creux de cette réalité parfois
              trop cynique. C’est à travers la poésie, les expérimentations
              musicales, les collages visuels et sonores, le clip, le dessin et,
              bien sûr, le cinéma, que nous donnons leur part de poésie et de
              beauté au glauque, au délabré, au déchet déchu auquel on limite
              trop souvent ce territoire.
              <br />
              <br />
              Le paysage en friches et en terrils de notre bassin minier natal
              est le terrain de jeu idéal pour aiguiser un regard absurde,
              mélancolique et critique sur le monde et son envers. “Cela aussi
              passera”, voulons-nous rappeler, en mettant en lumière et en
              images ces objets du passé avec lesquels on fabrique l’avenir.
            </p>
          </div>
        </div>
        <div className="textAbout">
          <h3 className="about-h3-title">Des décors et des corps</h3>
          <div className="about-bloc-para">
            <p className="about-p-text">
              Ces paysages noirs, verts et rouges ne sont pas vides. Plusieurs
              centaines de milliers de personnes y vivent et y projettent leurs
              propres rêves, leurs souvenirs et leurs désirs. Dans ces décors,
              des corps se meuvent, vieillissent, se transforment, tentant dans
              le même temps de s’approprier et de se délester d’histoires
              chargées de sens, parfois lourdes à porter.
              <br />
              <br />
              Histoires de la mine et de ses travailleurs, tour à tour fantasmés
              et oubliés.
              <br />
              <br />
              Histoires de sa surface, polluée et lardée de friches, mais aussi
              fierté locale érigée en patrimoine mondial de l’Humanité.
              <br />
              <br />
              Histoires des immigrations et des familles, mosaïque de langues et
              de sons dont sont tissées les cités.
              <br />
              <br />
              Histoires des quartiers, des rues, des usines et des luttes, qui
              résonnent encore des cris des enfants et des clameurs des grands.
              <br />
              <br />
              Des histoires, qui ont des corps et des décors, chacune
              extrêmement particulière mais toutes si universelles.
              <br />
              <br />
              C’est entre ces corps et ces décors, dans les fissures des peaux
              et les rides des murs, que prend place le projet Mines de Rien.
              <br />
              <br />
              De la mine, qu’on a creusé si longtemps, on ne tire plus rien
              maintenant. Juste une mémoire qui colle aux terrils, récifs ou
              bouées dans l’océan Gohelle.
              <br />
              <br />
              Du noir, du rouge et du vert, des images qui collent aux rétines
              comme la suie sur la peau.
              <br />
              <br />
              Mais aussi des histoires personnelles et collectives, de celles
              qu’on raconte au bar, aux voisins ou aux enfants. Des histoires
              qui sont autant de petits riens, qui font le quotidien, et dont se
              nourrit le projet Mines de Rien.
              <br />
              <br />
              Ici, il n’y a plus de charbon. En tout cas, on n’en veut plus. Ce
              qu’il reste, ce sont des images, des histoires et des sons, dont
              on tire une mine de rêveries.
            </p>
          </div>

          <div className="textAbout">
            <h3 className="about-h3-title">Nos inspirations</h3>
            <div className="about-bloc-para">
              <p className="about-p-text">
                Nos inspirations sont multiples et protéiformes. De l’imaginaire
                rêve-volté et néologique d’Alain Damasio à l’univers proto-punk
                de Patti Smith, en passant par l’humour sombre et désabusé de
                Manu Larcenet, la poésie souterraine de Thiéfaine ou encore
                l’affranchissement des codes de la composition de Gautier Serre,
                nous allons puiser dans diverses formes artistiques et
                différents courants pour construire un univers unique et
                lui-même pluriel.
                <br />
                <br />
                Côté cinéma, nos inspirations sont aussi vastes, tirant du côté
                de la mise en scène ironique et distanciée de Jim Jarmusch, des
                personnages révoltés de Bong Joon-Ho, de l’usage total et
                totalement libre des outils du cinéma de la Nouvelle Vague.
                Filmer le Bassin minier, ses alignements de corons et ses vastes
                plaines baignant les terrils, force par ailleurs le détour du
                côté du western de Sergio Leone.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default About;
