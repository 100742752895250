import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/js/dist/carousel';
import Footer from '../components/Footer';
import Carousel from '../components/Carousel';
import Casting from '../components/Casting';
import Synopsis from '../components/Synopsis';
import VideoPlayable from '../components/VideoPlayable';
import '../styles/VideoScreen.css';
// import { apiUrl } from "../datas/apiUrl";
// import { details } from "../datas/data";
import { useLocation } from 'react-router-dom';
// import NotFound from "./NotFound";
import { apiUrl, frontUrl } from '../datas/apiUrl';

function VideoScreen() {
  const [video, setVideo] = useState(['']);
  // const [isVideo, setIsVideo] = useState(false);
  const location = useLocation();
  // console.log(window.history.go(-1));

  useEffect(() => {
    if (location.state) {
      if ('id' in location.state && location.state.id !== undefined) {
        axios
          .get(`${apiUrl}/videos/${location.state.id}`)
          .then((response) => response.data)
          .then((data) => {
            // console.log(data);
            setVideo(data);
            // setIsVideo(true);
          })
          .catch((error) => {
            console.log(error.response.data);
          });
      }
    } else {
      axios
        .get(`${apiUrl}/videos`)
        .then((response) => response.data)
        .then((data) => {
          data.forEach((item) => {
            if (
              `${frontUrl}/video-library/${item.slug}` === window.location.href
            ) {
              axios
                .get(`${apiUrl}/videos/${item.id}`)
                .then((response) => response.data)
                .then((data) => {
                  // console.log(data);
                  setVideo(data);
                  // setIsVideo(true);
                })
                .catch((error) => {
                  console.log(error.response.data);
                });
            }
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const album = details.carouselItems;
  const cast = video.worker;
  // console.log('le tableau gallerie: ', video.gallery);

  // if (isVideo) {
  return (
    <div className="body">
      <div className="videoScreen-first-frame">
        <div className="videoScreen-title">
          <h1>
            <strong>{video.title}</strong>
          </h1>
          <div>
            <p>{video.groupName}</p>
          </div>
        </div>

        <div className="videoScreen-banner-frame">
          <VideoPlayable embedId={video.embedId} />
          <Synopsis text={video.content} bgi={video.fileUrl} />
        </div>
      </div>
      {cast !== undefined ? (
        <div className="videoScreen-casting-frame">
          <Casting teamArray={cast} />
        </div>
      ) : (
        <div className="card img-wrap">
          <p className="card-body">Patientez... Les données vont s'afficher</p>
        </div>
      )}
      {video.gallery !== undefined ? (
        <div className="videoScreen-carousel-frame">
          <Carousel gallery={video.gallery} />
        </div>
      ) : (
        <div className="card img-wrap">
          <p className="card-body">Patientez... Les données vont s'afficher</p>
        </div>
      )}

      <Footer />
    </div>
  );
  // } else {
  //   return (
  //     <div>
  //       <NotFound />
  //     </div>
  //   );
  // }
}

export default VideoScreen;
