import React from 'react';
import Banner from '../components/Banner';
import Footer from '../components/Footer';
import CounterVisits from '../components/CounterVisits';
import { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/Admin.css';
import { Link } from 'react-router-dom';
import { apiUrl } from '../datas/apiUrl';
import { useLocation } from 'react-router-dom';

function Admin() {
  const location = useLocation();

  const titlePage = 'Administration';
  const titleTextPage = 'Ajouter, Voir, Modifier ou supprimer des vidéos';

  const [usersDatas, setUsersDatas] = useState([]);
  // const [user, setUser] = useState([]);
  // const [userId, setUserId] = useState();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [error401, setError401] = useState(false);
  const search = (obj) => obj.email === location.state.username;

  useEffect(() => {
    if (window.localStorage.getItem('Bearer')) {
      // Dans le cas où il y a un token
      axios
        .get(`${apiUrl}/users`, {
          headers: {
            Accept: 'Application/json',
            Authorization: 'Bearer ' + window.localStorage.getItem('Bearer'),
          },
        })
        .then((response) => response.data)
        .then((data) => {
          // console.log('le usersData: ', data);
          setUsersDatas(data);
          console.log('Connecté');
          setIsLoggedIn(true);
        })
        .catch((error) => {
          console.log(error);
          setError401(true);
        });
    } else {
      // Dans le cas où on n'a pas de token
      console.log('Non connecté');
      setIsLoggedIn(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const theUser = location.state
    ? usersDatas[usersDatas.findIndex(search)]
    : undefined;
  // console.log(usersDatas.findIndex(search));
  // console.log('THE User: ', theUser);
  // console.log('le user: ', user);

  const logout = () => {
    window.localStorage.removeItem('Bearer');
    setIsLoggedIn(false);
  };

  const deleteUser = (id) => {
    axios
      .delete(`${apiUrl}/users/${id}`, {
        headers: {
          Authorization: 'Bearer ' + window.localStorage.getItem('Bearer'),
        },
      })
      .then(function (response) {
        //handle success
        // console.log(response);
        axios
          .get(`${apiUrl}/users`, {
            headers: {
              Accept: 'Application/json',
              Authorization: 'Bearer ' + window.localStorage.getItem('Bearer'),
            },
          })
          .then((response) => response.data)
          .then((data) => {
            // console.log(data);
            setUsersDatas(data);
          });
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };

  const usersList = usersDatas.map(({ id, email, firstname }) => (
    <li key={id + firstname}>
      <h2>
        <strong>{firstname}</strong>:
      </h2>
      <p>Numéro identifiant: {id}</p>
      <p>Adresse mail: {email}</p>
      <button
        type="button"
        class="btn btn-danger"
        onClick={() => deleteUser(id)}
      >
        Supprimer
      </button>
    </li>
  ));

  return (
    <div>
      <div>
        <Banner
          title={titlePage}
          titleText={titleTextPage}
          userDatas={theUser}
        />
      </div>
      <div className="admin">
        <div className="admin-content">
          <h2 className="text-admin">
            <button onClick={logout}>
              <Link className="off-link amin-co" to="/login">
                {isLoggedIn ? 'Se déconnecter' : 'Se connecter'}
              </Link>
            </button>
          </h2>
        </div>
        {!error401 && isLoggedIn ? (
          <div>
            <div className="admin-content-isLoggedIn">
              <h2 className="text-admin">
                <a href="add-videos" className="accueil-menu-title-href">
                  Ajouter des vidéos
                </a>
              </h2>
              <h2 className="text-admin">
                <a href="add-image-video" className="accueil-menu-title-href">
                  Modifier l'image de couverture d'une vidéo
                </a>
              </h2>
              <h2 className="text-admin">
                <a
                  href="modify-titlebanner"
                  className="accueil-menu-title-href"
                >
                  Modifier la vidéo d'entête de la page "réalisations"
                </a>
              </h2>
              <h2 className="text-admin">
                <a
                  href="modify-video-infos"
                  className="accueil-menu-title-href"
                >
                  Modifier ou supprimer une vidéo
                </a>
              </h2>
              <h2 className="text-admin">
                <a href="add-gallery" className="accueil-menu-title-href">
                  Ajouter des photos pour la gallery d'une vidéo
                </a>
              </h2>
              <h2 className="text-admin">
                <a href="add-casting" className="accueil-menu-title-href">
                  Ajouter quelqu'un qui n'a travaillé pour mines de rien hein
                </a>
              </h2>
              <h2 className="text-admin">
                <a href="register" className="accueil-menu-title-href">
                  Inscrire un nouvel utilisateur
                </a>
              </h2>
            </div>
            <div className="usersList">
              <h2>
                {!isLoggedIn
                  ? 'Vous devez vous connecter pour voir la liste des utilisateurs'
                  : 'Liste des utilisateurs'}
              </h2>

              {usersList}

              <CounterVisits />
            </div>
          </div>
        ) : (
          // <div className="admin">
          <p>Veuillez vous connecter</p>
          // </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Admin;
