import React from 'react';
import { useState, useEffect } from 'react';
import { api2Url, apiUrl, uri } from '../datas/apiUrl';
import axios from 'axios';
import '../styles/VideoLibrary.css';
import Video from '../components/Video';
import BandList from '../components/BandList';
import Footer from '../components/Footer';
import chevronUp from '../assets/img/chevronUp.png';
import rgLoader from '../assets/picto/rgLoader.gif';

function VideoLibrary() {
  const [videos, setVideos] = useState([]);
  const [allVideos, setAllVideos] = useState([]);
  // const [visitsToSend, setVisitsToSend] = useState(0);
  const [categoryData, setCategoryData] = useState([]);

  const [categoriesNav, setCategoriesNav] = useState([]);

  // useEffect(() => {
  //   let isMounted = true;
  //   axios
  //     .get(`${api2Url}${categoryUri}`)
  //     .then((response) => response.data)
  //     .then((data) => {
  //       if (isMounted) setCategoryName(data.name);
  //     })
  //     .catch((error) => {
  //       console.log(error.response);
  //     });
  //   return () => {
  //     isMounted = false;
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  useEffect(() => {
    axios
      .get(`${api2Url}/api/categories`)
      .then((response) => response.data)
      .then((data) => {
        // console.log('le cat data: ', data);
        setCategoryData(data);
        const categories = data.reduce(
          (acc, category) =>
            acc.includes(category.name) ? acc : acc.concat(category.name),
          []
        );
        categories.unshift('Tout');
        setCategoriesNav(
          categories.map((category) => ({
            name: category,
            current: false,
          }))
        );
      })
      .catch((error) => {
        console.log(error.response);
      });

    axios
      .get(`${apiUrl}/videos`)
      .then((response) => response.data)
      .then((data) => {
        // console.log(data);
        setVideos(data);
        setAllVideos(data);
      })
      .catch((error) => {
        console.log(error.response);
      });
    let videoLibrary;
    let total;
    axios
      .get(`${apiUrl}/counter_visits/1`)
      .then((response) => response.data)
      .then((data) => {
        // console.log(
        //   'le data pour le visitToSend avant tout changement: ',
        //   data
        // );
        // setVisitsToSend(data);
        videoLibrary = data.videoLibraryVisits;
        total = data.totalVisits;
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        };
        const bodyParameters = {
          totalVisits: total + 1,
          videoLibraryVisits: videoLibrary + 1,
        };
        axios
          .put(`${apiUrl}/counter_visits/1`, bodyParameters, config)
          .then(function (response) {
            //handle success
            // console.log(response);
            // setVisitsToSend({
            //   videoLibraryVisits: bodyParameters.videoLibraryVisits,
            //   totalVisits: bodyParameters.totalVisits
            // });
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, []);

  // const allCategories = categories.reduce(
  //   (acc, category) =>
  //     acc.includes(category.name) ? acc : acc.concat(category.name),
  //   []
  // );
  // const categoriesNav = allCategories.map((category) => ({
  //   name: category,
  //   current: false,
  // }));

  // Pour avoir le nombre de visites apparaitre dans le localStorage =>
  // let nbVisits;
  // if(typeof localStorage!='undefined') {
  //   // Récupération de la valeur dans web storage
  //   console.log('le localStorage: ', localStorage.getItem('videoLibraryVisits'));
  //   nbVisits = localStorage.getItem('videoLibraryVisits');
  //   console.log('le nbVisits après le passage du localStorage', nbVisits);
  //   // Vérification de la présence du compteur
  //   if(nbVisits!==null) {
  //     // Si oui, on convertit en nombre entier la chaîne de texte qui fut stockée
  //     nbVisits=parseInt(nbVisits);
  //     console.log('nbvisits pas égal à null: ', nbVisits);
  //   } else {
  //     nbVisits=0;
  //     console.log('nbVisits égal à null donc égal maintenant à: ', nbVisits);
  //   }
  //   // Incrémentation
  //   nbVisits++;
  //   console.log('incrémentation nbVisits: ', nbVisits);
  //   // Stockage à nouveau en attendant la prochaine visite...
  //   localStorage.setItem('videoLibraryVisits', nbVisits);

  //   let totalVisits;
  //   if(localStorage.getItem('allVisits') !== null) {
  //   totalVisits = parseInt(localStorage.getItem('allVisits'));
  //   } else {
  //     totalVisits=0;
  //   }
  //   totalVisits++;
  //   localStorage.setItem('allVisits', totalVisits);
  //   console.log('total: ', localStorage.getItem('allVisits'));
  //   console.log('le localstorage: ', localStorage.getItem('visits'));
  //   // Affichage dans la page
  //   setVisits(nbVisits);
  //   // enregistrer dans un fichier, une session ou dans la bdd
  //   setStoreVisits({
  //     visits: nbVisits
  //   });
  // } else {
  //   alert("localStorage n'est pas supporté !");
  // }

  // const groupNames = videos.reduce(
  //   (acc, band) =>
  //     acc.includes(band.groupName) ? acc : acc.concat(band.groupName),
  //   []
  // );

  // const groupNameList = groupNames.map((gName, index) => (
  //   <div className={groupNames.indexOf(gName) % 2 !== 0 ? "bgi" : "bgi2"} key={gName.toString() + index}>
  //     <div className="video-list-frame">
  //       <ul className="mdr-clip-list">
  //         {videos.map(
  //           ({
  //             category,
  //             content,
  //             fileUrl,
  //             coverDescription,
  //             coverId,
  //             embedId,
  //             groupName,
  //             id,
  //             title,
  //             slug,
  //             duration,
  //             releaseDate,
  //             groupLink,
  //           }) => (
  //             <li key={id + slug}>
  //               <BandList
  //                 indexForBgi={groupNames.indexOf(gName) % 2}
  //                 categoryUri={category}
  //                 slug={slug}
  //                 gName={gName}
  //                 content={content}
  //                 cover={fileUrl}
  //                 coverDescription={coverDescription}
  //                 coverId={coverId}
  //                 embedId={embedId}
  //                 groupName={groupName}
  //                 id={id}
  //                 title={title}
  //                 duration={duration}
  //                 releaseDate={releaseDate}
  //                 groupLink={groupLink}
  //               />
  //             </li>
  //           )
  //         )}
  //       </ul>
  //     </div>
  //   </div>
  // ));

  const title = 'Réalisations';
  const titleText = 'Découvrez nos dernières réalisations';

  // const truc = [
  //   { name: 'Tout', current: false },
  //   { name: 'Musique', current: false },
  //   { name: 'Atelier', current: false },
  //   { name: 'String', current: false },
  //   { name: 'Captation', current: false },
  // ];

  // categoriesNav.push({ name: 'Tout', current: false });
  // setCategNav(categoriesNav);
  // console.log('le test: ', categoriesNav);

  // console.log('le truc qui va : ', truc);
  // const [categNav, setCategNav] = useState(categoriesNav);
  // console.log('the categNav avant le map : ', categNav);

  function filterCategories(category) {
    // console.log('le catData dans le filterCat: ', categoryData[0].videos);
    document.getElementById('mdr-clip').scrollIntoView(true);
    let theIndex;
    const filteredCategoriesNav = categoriesNav.filter((categoryNav, index) =>
      category === categoryNav.name
        ? ((theIndex = index), (categoryNav.current = true))
        : (categoryNav.current = false)
    );
    let filteredCategNav = categoriesNav.filter(
      (categoryNav) => category !== categoryNav.name
    );
    filteredCategNav.splice(theIndex, 0, filteredCategoriesNav[0]);
    setCategoriesNav(filteredCategNav);

    categoryData.map((cat) =>
      cat.name === category
        ? setVideos(
            allVideos.filter(
              (video) => video.category === `${uri}/categories/${cat.id}`
            )
          )
        : null
    );

    if (category === 'Tout') {
      setVideos(allVideos);
    }
    // if (category === 'Création original') {
    //   setVideos(
    //     allVideos.filter((video) => video.category === '/api/categories/6')
    //   );
    // }
    // if (category === 'Atelier / Résidence') {
    //   setVideos(
    //     allVideos.filter((video) => video.category === '/api/categories/2')
    //   );
    // }
    // if (category === 'Teasers') {
    //   setVideos(
    //     allVideos.filter((video) => video.category === '/api/categories/7')
    //   );
    // }
    // if (category === 'Captations') {
    //   setVideos(
    //     allVideos.filter((video) => video.category === '/api/categories/8')
    //   );
    // }
  }

  // function showNoVideos() {
  //   document.getElementById('noVideos').style.display = 'block';
  //   document.getElementById('loader').style.display = 'none';
  // }

  // console.log('les vidéos: ', videos);

  function showNoVideos() {
    // console.log('on affiche le chargement');

    //   document.getElementById('noVideos-frame').style.display = 'block';
    // }

    setTimeout(() => {
      if (
        document.getElementById('noVideos') !== null &&
        document.getElementById('loader') !== null
      ) {
        document.getElementById('noVideos').style.display = 'block';
        document.getElementById('loader').style.display = 'none';
      }
    }, '5000');
  }
  // function showIsVideos() {
  //   console.log('on affiche plus le chargement');
  //   // if (document.getElementById('noVideos') !== null) {
  //   document.getElementById('noVideos').style.display = 'none';
  //   document.getElementById('noVideos').style.display = 'none';
  //   document.getElementById('loader').style.display = 'none';
  //   // }
  // }

  return (
    <div className="videoLibrary-bgc">
      <div className="videoLibrary-banner-video-frame">
        {/* <p style={{...{width: '100%'}, ...{color: 'yellow'}, ...{position: 'absolute'}, ...{textAlign: 'right'}, ...{paddingRight: '15px'}, ...{display: 'inline-block'}}}>Toutes les pages du sites ont été vu au total <span>{visitsToSend.totalVisits}</span> fois. Et cette page a été vu <span>{visitsToSend.videoLibraryVisits}</span> fois</p> */}
        <Video title={title} titleText={titleText} />
      </div>
      <div id="mdr-clip"></div>
      {/* {groupNameList} */}
      {/* <div className="videoLibrary-ul-frame"> */}
      <div className="toTop">
        <div id="top" onClick={() => window.scrollTo(0, 0)}>
          <img src={chevronUp} alt="chevron haut" width={33} height={33} />{' '}
        </div>
      </div>
      <div className="categories-frame">
        <div className="categories">
          {categoriesNav.map((item) => (
            <div
              key={item.name}
              onClick={(e) => filterCategories(item.name)}
              className={`${
                item.current ? 'btnFilteredCategory' : 'btnFilterCategory'
              }`}
            >
              {item.name}
            </div>
          ))}

          {/* <div onClick={(e) => filterCategories('Tout')}>Tous</div>
          <div onClick={(e) => filterCategories('Musique')}>
            Création original
          </div>
          <div onClick={(e) => filterCategories('Atelier')}>
            Atelier / Résidence
          </div>
          <div onClick={(e) => filterCategories('string')}>Teasers</div>
          <div
            onClick={(e) => filterCategories('Captation')}
            className={`${categoriesNav.current ? 'btnFilterCategory' : ''}`}
          >
            Captation
          </div> */}
        </div>
      </div>
      <ul className="mdr-clip-list">
        {!videos.length
          ? (showNoVideos(),
            (
              <div id="noVideos-frame">
                <img
                  src={rgLoader}
                  alt="gif de chargement"
                  width={100}
                  height={100}
                  id="loader"
                />
                <p id="noVideos">
                  Pas de vidéos dans cette catégorie pour le moment
                </p>
              </div>
            ))
          : videos
              .sort((a, b) => (a.releaseDate < b.releaseDate ? 1 : -1))
              .map(
                ({
                  category,
                  content,
                  fileUrl,
                  coverDescription,
                  coverId,
                  embedId,
                  groupName,
                  id,
                  title,
                  slug,
                  duration,
                  releaseDate,
                  groupLink,
                }) => (
                  <li key={id + slug}>
                    <BandList
                      // indexForBgi={groupNames.indexOf(gName) % 2}
                      categoryUri={category}
                      slug={slug}
                      // gName={gName}
                      content={content}
                      cover={fileUrl}
                      coverDescription={coverDescription}
                      coverId={coverId}
                      embedId={embedId}
                      groupName={groupName}
                      id={id}
                      title={title}
                      duration={duration}
                      releaseDate={releaseDate}
                      groupLink={groupLink}
                    />
                  </li>
                )
              )}
      </ul>
      {/* </div> */}
      <Footer />
    </div>
  );
}

export default VideoLibrary;
