import '../styles/Footer.css';
import logo from '../assets/logos/logo-white.png';
import { SocialIcon } from 'react-social-icons';

export default function Footer() {
    return (
      <div className="frame">
        <div>
          <a href="contact" className="mdr-contact-footer">
            Nous contacter
          </a>
          <div className="mdr-logo-footer">
            <img
              src={logo}
              alt="logo mines de rien cinéma"
              className="logo-footer"
            />
          </div>
                <div className="footer-social">
                    <SocialIcon url="https://www.facebook.com/minesderiencinema" bgColor="white" />
                    <SocialIcon url="https://www.instagram.com/mines_de_rien_cinema/" bgColor="white" />
                </div>
          <p className="mdr-copyright">
            Copyright © Minesderien-cinema.fr 2021 - Tous droits réservés - FAQ
          </p>
          <p className="mdr-site-creator">- Créé par SLYDEVWEB -</p>
        </div>
      </div>
    );
}
