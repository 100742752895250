import '../styles/Video.css';
// import video1 from '../assets/videos/videoCoverr.mp4';
import arrowImage from '../assets/img/downArrowWhite.png';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { api2Url } from '../datas/apiUrl';

function Video({ title, titleText }) {
  const [videoBanner, setVideoBanner] = useState({});

  useEffect(() => {
    axios
      .get(`${api2Url}/api/videobanners`)
      .then((response) => response.data)
      .then((data) => {
        setVideoBanner(data[0]);
      });
  }, []);

  return (
    <div className="video-content">
      <div className="video-videoLibrary-title">
        <h1>
          <strong>{title}</strong>
        </h1>
        <div>
          <p>{titleText}</p>
        </div>
      </div>
      <div className="video-container">
        <div className="video-frame">
          <p className="video-title">{videoBanner.title}</p>
          <video
            width="853"
            height="480"
            src={
              videoBanner.fileUrl !== undefined && videoBanner.fileUrl !== null
                ? `${api2Url}${videoBanner.fileUrl}`
                : ''
            }
            autoPlay
            loop
            muted
            frameBorder="0"
            className="video-video"
          ></video>
        </div>
      </div>
      <div className="library-arrow-frame">
        <div className="library-arrow">
          <p className="library-arrow-scroll-text">Scroll</p>
          <img
            src={arrowImage}
            alt="flêche vers le bas"
            width={15}
            height={25}
            className="library-arrow-img"
          />
        </div>
        {/* <div className="library-arrow">
          <p className="library-arrow-scroll-text">Scroll</p>
          <img
            src={arrowImage}
            alt="flêche vers le bas"
            width={15}
            height={25}
            className="library-arrow-img"
          />
        </div> */}
      </div>
    </div>
  );
}

export default Video;
