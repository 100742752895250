import 'bootstrap/js/dist/carousel'
import 'bootstrap/js/dist/button'
import '../styles/Carousel.css'
import { api2Url } from "../datas/apiUrl";
import { useState } from 'react';

export default function Carousel({ gallery }) {

  // console.log('la gallery du carousel: ', gallery.length);
    // let galleryObject = Object.values(gallery)[0];
    // console.log(galleryObject.id);

    
    let buttons;

    if (gallery !== undefined) { 
      buttons = gallery.map(( { id },index ) => (
        <button key={id+index} type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={index} className={(index === 0) ? "active" : ""} aria-current={(index === 0) ? "true" : "false"} aria-label={"Slide " + (index + 1)}></button>
      ))
    }

    let items;
    if (gallery !== undefined) { 
      items = gallery.map(({id, fileUrl, description, altDescription}, index) => (
      <div key={index} className={(index === 0) ? "carousel-item active imageBox" : "carousel-item imageBox"} data-bs-interval="3000">
              <img src={
                fileUrl !== undefined && fileUrl !== null
                ? `${api2Url}${fileUrl}`
                : "" }
                className="d-block w-100 carouselImg"
                alt={altDescription}
                onClick={enlargeImg}
                id={`img${id}`}
                />
              <div className="carousel-caption d-none d-md-block">
                  <p>{description}</p>
              </div>
          </div>
      ))
    }
  
  // console.log('les images: ', items);

  // let itemsMapped = items.map((member, index) => (
    // console.log('itemsMapped: ', member.props.children[0].props.src)
    // <img  key={index} src={member.props.children[0].props.src}
    // className="d-block w-100 imgSize"
    // alt={member.props.children[0].props.src}
    // onClick={enlargeImg}
    // id={`img${id}`}
  //   />
  // ))

  // console.log(itemsMapped);
  const [imgTarget, setImgTarget] = useState("");
  const [imgAltDescription, setImgAltDescription] = useState("");
  const [carouselDisplay, setCarouselDisplay] = useState({display: 'block'});
  
  // console.log('carouselDisplay: ', carouselDisplay);

  function enlargeImg(e) {
    e.preventDefault();
    // console.log('click agrandissage image: ', e.target.src);
    setImgTarget(e.target.src);
    setImgAltDescription(e.target.alt);
    setCarouselDisplay({display: 'none'})
  }

  function unZoom(e) {
    e.preventDefault();
    // console.log('click dézoomage');
    setImgTarget("");
    setImgAltDescription("");
    setCarouselDisplay({display: 'block'})
  }

  // console.log(imgTarget);

    if (gallery.length !== 0) {
    return (
      <div className="container">
        <div className="card">
          <p className="card-header">
            <strong>Photographies de tournage</strong>
          </p>

      <img src={imgTarget}
        className="imgSize"
        alt={imgAltDescription}
        onClick={unZoom}
        // id={`img${id}`}
      />

          <div
            id="carouselExampleCaptions"
            style={carouselDisplay}
            className='carousel slide'
            data-bs-ride="carousel"
          >
            
          <div className='carousel-inner-indicators'>
            <div 
              className="carousel-inner" 
            >
              <p className="click" >Cliquez pour agrandir</p>
              {items ? items : "Pas de données pour le moment"}
            </div>
            <div className="carousel-indicators">
              {buttons ? buttons : "Pas de données pour le moment"}
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide="prev"
              >
              <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
              </button>
              <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide="next"
              >
              <span
              className="carousel-control-next-icon"
              aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
            </div>
          </div>


        </div>
      </div>
    )
  } else {
    return (
      <div className='container'>
        <div className="card">

          <p className="card-header">
            <strong>Photographies de tournage</strong>
          </p>
        </div>

        <div className="card img-wrap">
          <p className='card-body'>Patience ! Les photos du tournage vont arriver !</p>
        </div>

      </div>
    )
  }
}
