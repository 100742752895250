import React from 'react';
import Banner from '../components/Banner';
import Footer from '../components/Footer';
import { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/AddImageVideo.css';
import '../styles/ModifyInfosVideo.css';
import { apiUrl } from '../datas/apiUrl';

function AddImageVideo() {
  const titlePage = 'Modifier une vidéo';
  const titleTextPage = "Ici modifiez les infos d'une vidéo";

  const [videos, setVideos] = useState([]);
  const [videoId, setVideoId] = useState('');
  const [groupName, setGroupName] = useState('');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [coverDescription, setCoverDescription] = useState('');
  const [embedId, setEmbedId] = useState('');
  const [duration, setDuration] = useState('');
  const [releaseDate, setReleaseDate] = useState('');
  const [groupLink, setGroupLink] = useState('');

  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState('');

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  //   const [file, setFile] = useState('');

  function setSelectedVideo(e) {
    e.preventDefault();
    const theSelectedVideo = videos.filter(
      (video) => video.id === +e.target.value
    );
    setVideoId(theSelectedVideo[0].id);
    setGroupName(theSelectedVideo[0].groupName);
    setTitle(theSelectedVideo[0].title);
    setContent(theSelectedVideo[0].content);
    setCoverDescription(theSelectedVideo[0].coverDescription);
    setEmbedId(theSelectedVideo[0].embedId);
    setDuration(theSelectedVideo[0].duration);
    setReleaseDate(theSelectedVideo[0].releaseDate);
    setGroupLink(theSelectedVideo[0].groupLink);
  }

  useEffect(() => {
    const url = `${apiUrl}/videos`;
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        // console.log(data);
        setVideos(data);
      });
    if (window.localStorage.getItem('Bearer')) {
      // Dans le cas où il y a un token
      const url = `${apiUrl}/videos`;
      axios
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          console.log(data);
        });
      console.log('Connecté');
      setIsLoggedIn(true);
      axios
        .get(`${apiUrl}/categories`)
        .then((response) => response.data)
        .then((data) => {
          // console.log(data);
          setCategories(data);
        })
        .then((data) => {
          console.log(data);
        });
    } else {
      // Dans le cas où on n'a pas de token
      console.log('Non connecté');
      setIsLoggedIn(false);
    }
  }, []);

  const optionCategory = categories.map(({ id, name }) => (
    <option key={id} value={id}>
      {id}: {name}
    </option>
  ));

  const clearForm = () => {
    document.getElementById('modify-infos-form').reset();
  };

  const deleteHandler = () => {
    let askYesOrNo = window.confirm(
      'ête vous sûr de vouloir supprimer la vidéo ?'
    );
    if (askYesOrNo) {
      const config = {
        headers: {
          Accept: 'Application/json',
          Authorization: 'Bearer ' + window.localStorage.getItem('Bearer'),
        },
      };
      axios
        .delete(`${apiUrl}/videos/${videoId}`, config)
        .then(function (response) {
          //handle success
          // console.log(response);
          clearForm();
          setGroupName('');
          setTitle('');
          setContent('');
          setCoverDescription('');
          setEmbedId('');
          setDuration('');
          setReleaseDate('');
          setGroupLink('');
          setVideoId('');
          alert('la vidéo a bien été supprimée.');
        })
        .catch(function (error) {
          //handle error
          // console.log(error.response);
          alert("La vidéo n'a pas été supprimée");
        });
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        Accept: 'Application/json',
        Authorization: 'Bearer ' + window.localStorage.getItem('Bearer'),
      },
    };
    const bodyParameters = {
      groupName: groupName,
      title: title,
      duration: duration,
      releaseDate: releaseDate,
      groupLink: groupLink,
      slug: (groupName + title)
        .replace(/[^a-zA-Z ]/g, '')
        .replace(/[' ']/g, '-')
        .replace(/-+/g, '-')
        .toLowerCase(),
      content: content,
      coverId: (title + groupName)
        .replace(/[^a-zA-Z ]/g, '')
        .replace(/[' ']/g, '-')
        .replace(/-+/g, '-')
        .toLowerCase(),
      coverDescription: coverDescription,
      embedId: embedId.split('/').pop(),
      category: `/api/categories/${categoryId}`,
    };
    axios
      .put(`${apiUrl}/videos/${videoId}`, bodyParameters, config)
      .then(function (response) {
        //handle success
        // console.log(response);
        clearForm();
        setGroupName('');
        setTitle('');
        setContent('');
        setCoverDescription('');
        setEmbedId('');
        setDuration('');
        setReleaseDate('');
        setGroupLink('');
        setVideoId('');
        alert('la vidéo a bien été modifiée.');
      })
      .catch(function (error) {
        //handle error
        console.log(error.response);
        alert("La vidéo n'a pas été enregistrée");
      });
  };

  const option = videos.map(({ groupName, title, id }) => (
    <option key={id} value={id}>
      {groupName}: {title}
    </option>
  ));

  if (isLoggedIn) {
    return (
      <div>
        <Banner title={titlePage} titleText={titleTextPage} />
        <div className="addImageVideo">
          <h1 className="text-addImageVideo">
            Modifier ou supprimer une vidéo
          </h1>
          <form
            id="modify-infos-form"
            encType="multipart/form-data"
            onSubmit={handleFormSubmit}
            className="form addImageVideo-form"
          >
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => setSelectedVideo(e)}
              required
            >
              <option value="">Sélectionnez un titre</option>
              {option}
            </select>
            <input
              type="button"
              value="Supprimer la vidéo ?"
              className={`btnDelete ${
                videoId === '' ? 'noVideoId' : 'isVideoId'
              }`}
              onClick={() => deleteHandler()}
            />
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => setCategoryId(e.target.value)}
              required
            >
              <option value="">Sélectionnez une catégorie</option>
              {optionCategory}
            </select>

            <label>Nom du groupe ou de l'organimse</label>
            <input
              type="text"
              name="groupName"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              required
            />

            <label>Titre</label>
            <input
              type="text"
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />

            <label>Durée</label>
            <input
              type="text"
              name="title"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            />

            <label>Date de sortie</label>
            <input
              type="text"
              name="title"
              value={releaseDate}
              onChange={(e) => setReleaseDate(e.target.value)}
            />

            <label>Lien vers le site du groupe/organisme</label>
            <input
              type="text"
              name="title"
              value={groupLink}
              onChange={(e) => setGroupLink(e.target.value)}
            />

            <label>Description du titre</label>
            <textarea
              type="text"
              name="content"
              value={content}
              onChange={(e) => setContent(e.target.value)}
            ></textarea>

            <label>Description de l'image de couverture</label>
            <input
              type="text"
              name="coverDescription"
              value={coverDescription}
              onChange={(e) => setCoverDescription(e.target.value)}
              required
            />

            <label>Code de la vidéo</label>
            <input
              type="text"
              name="embedId"
              value={embedId}
              onChange={(e) => setEmbedId(e.target.value)}
              required
            />

            {/* <label>Image de couverture</label>
          <input
            type="file"
            name="file"
            onChange={(e) => setFile(e.target.files[0])}
          /> */}
            <input className="btn-dark" type="submit" value="Envoyer" />
          </form>
          <Footer />
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Banner title={titlePage} titleText={titleTextPage} />
        <div className="addVideo">
          <h1 className="textAddVideo">
            Vous n'avez pas accés à l'espace administration. <br />
            Veuillez vous connecter.
          </h1>
        </div>
        <Footer />
      </div>
    );
  }
}

export default AddImageVideo;
